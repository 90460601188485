import React, { useContext, useState, useCallback } from "react"

const CompanyContext = React.createContext({
  companyId: null,
  companyName: null,
  companyPhone: null,
  companyLogo: null,
  username: null,
  userFullName: null,
  userPhoto: null,
  services: null,
  managements: [],
  permissions: [],
  setData: ({
    companyId, companyName, companyPhone, companyLogo, username,
    userFullName, userPhoto, services, managements, permissions
  }) => {},
})


export const CompanyProvider = (props) => {
  const [{
    companyId,
    companyName,
    companyPhone,
    companyLogo,
    username,
    userFullName,
    userPhoto,
    services,
    managements,
    permissions
  }, setState] = useState({
    companyId: null,
    companyName: null,
    companyPhone: null,
    companyLogo: null,
    username: null,
    userFullName: null,
    userPhoto: null,
    services: [],
    managements: [],
    permissions: []
  })

  const setData = useCallback(({
    companyId, companyName, companyPhone, companyLogo, username,
    userFullName, userPhoto, services, managements, permissions
  }) => {
    setState({
      companyId,
      companyName,
      companyPhone,
      companyLogo,
      username,
      userFullName,
      userPhoto,
      services,
      managements,
      permissions
    })
  }, [])

  const companyContext = {
    companyId, companyName, companyPhone, companyLogo,
    username, userFullName, userPhoto, services,
    managements, permissions, setData,
  }

  return (
    <CompanyContext.Provider value={companyContext} >
      { props.children }
    </CompanyContext.Provider>
  )
}


export const useCompany = () => useContext(CompanyContext)

export default CompanyContext;
