import React, { useEffect } from 'react'
import io from 'socket.io-client'
import { WEB_SOCKET } from 'api/urls'
import { useSocket } from 'store/socket-context'
import { store } from 'react-notifications-component'
import { Media } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { dashboardActions } from 'store/slices/dashboard-slice'
import { shortName } from 'helpers/utils'

export const useInitSocket = () => {
  const { setSocket } = useSocket()
  useEffect(() => {
    const newSocket = io(WEB_SOCKET)
    setSocket(newSocket)
    return () => newSocket.close()
  }, [setSocket])
}

export const useUpdater = (management_id) => {
  const { socket } = useSocket()
  const dispatch = useDispatch()

  useEffect(() => {
    if(!socket && !management_id) return

    console.log("I am Here BKLALALLA")
    console.log(socket)

    socket.on('notification', (payload) => {
      console.log(payload)
      dispatch(dashboardActions.setNotification(payload.data.total))
    });

    socket.on('payment', payload => {
      console.log(payload)
      dispatch(dashboardActions.setPayment(payload.data.total))
    });


    socket.on('event', payload => {
      console.log(payload)
      let type = (payload.data.type === 'payment') ? 'default' : 'success'
      let message =
        <Media className="align-items-center">
          <Media className="d-lg-block pr-3">
            {(payload.data.photo) ? <span className="avatar avatar-sm rounded-circle">
              <img
                alt="..."
                src={payload.data.photo}
              />
            </span> :
              <span className="profile-rounded-circle profile-rounded-circle-sm">
                {shortName(payload.data.inspector)}
              </span>
            }
          </Media>
          <Media className="d-lg-block">
            <span className="mb-0">
              {payload.data.inspector} tərəfindən {payload.data.amount} AZN {(payload.data.type === 'payment') ? 'ödəniş qəbzi' : 'bildiriş xəbərdarlıq'} təqdim olundu.
            </span>
          </Media>
        </Media>;


      store.addNotification({
        message: message,
        type: type,
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 10000,
          onScreen: true
        }
      });
    })

    socket.emit('joinManagement', {
      management_id: management_id,
    })
  }, [socket, management_id, dispatch])
}
