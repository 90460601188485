
var routes = [
  {
    path: "/dashboard",
    name: "Ümumi baxış",
    icon: "fa fa-home",
    // layout: "/finance",
    dropdown: false,
  },
  {
    path: "/payers",
    name: "Abonentlər",
    icon: "fas fa-user-friends",
    dropdown: false,
    // layout: "/finance",
  },
  {
    path: "/inspectors",
    name: "Müfəttişlər",
    icon: "far fa-address-card",
    dropdown: false,
    // layout: "/finance",
  },
  {
    path: "/regions",
    name: "Rayon - küçə - ev (bina)",
    icon: "fa fa-building",
    // layout: "/finance",
    dropdown: false,
  },
  {
    path: "/bankbills",
    name: "Bank Ödənişləri",
    icon: "far fa-credit-card",
    // layout: "/finance",
    dropdown: false,
  },
  {
    path: "/deletebills",
    name: "Silinmiş Qəbzlər",
    icon: "fas fa-receipt",
    // layout: "/finance",
    dropdown: false,
  },
];

export default routes;
