import { PAYER_DETAIL } from "api/urls";

export const updatePayerData = async (auth, id, updatedData) => {

  const URL = PAYER_DETAIL + `${id}/`;
  if (updatedData) {
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        'Authorization': 'Token ' + auth.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData)
    })
    if (!response.ok) {
      if(response.status === 401) auth.logout()
      throw Error('An error occured')
    }
    return response.status;
  }

  return null
}

export const deletePayer = async (auth, id) => {

  const URL = PAYER_DETAIL + `${id}/`;
  const response = await fetch(URL, {
    method: "DELETE",
    headers: {
      'Authorization': 'Token ' + auth.token,
      'Content-Type': 'application/json',
    }
  });
  if (!response.ok) {
    if(response.status === 401) auth.logout()
    throw Error('An error occured')
  }

  return response.status
}

