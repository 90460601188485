import React from 'react';

import BarData from 'components/charts/BarData';
// import PieData from 'components/charts/PieData';
// import Meter from 'components/index/Meter';

import { useParams } from "react-router-dom";

const Statistic = props => {
  const { id } = useParams();

  return (
    <BarData header="Hesabat" inspector={id} />
  );
}


export default Statistic;
