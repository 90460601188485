import React, { useCallback, useState } from "react"

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  FormGroup,
  Form,
  Label,
  Input
} from "reactstrap"

import { useSaveRegion } from "hooks/address"


const RegionAddModal = ({ isOpen, toggle }) => {

  const [name, setName] = useState(undefined)
  const [save, setSave] = useState(false)

  useSaveRegion(save, name, setSave)

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault()
    if (e.target.checkValidity()) setSave(true)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={toggle}>Yeni Rayon / küçə</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleOnSubmit} id='region-modal-form'>
          <FormGroup>
            <Label>Adı *</Label>
            <Input
              placeholder="Adı"
              type="text"
              className="form-control form-control-sm"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="info"
          disabled={save}
          type='submit'
          form="region-modal-form"
          className="btn-sm"
        >
          Yadda saxla
        </Button>
        {' '}
        <Button
          className="ml-auto btn-sm"
          color="link"
          onClick={toggle}
        >
          Ləğv et
        </Button>
      </ModalFooter>
    </Modal>
  )
}


export default RegionAddModal
