import React, { useEffect, useCallback, useState } from "react";
import { NAVBAR_SEARCH } from "api/urls";

import {
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Input,
  InputGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  Badge
} from "reactstrap";

import { useParams } from "react-router-dom";

const useSearch = ({value, changeShow}) => {
  const [result, setResult] = useState([])

  useEffect(() => {
    const search = async () => {
      const response = await fetch(NAVBAR_SEARCH + "?search=" + value, {
        method: "GET",
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        }
      })

      const data = await response.json()

      if (data && data.length) {
        changeShow(true)
      } else {
        changeShow(false)
      }
      setResult(data)
    }

    if (value) {
      const timeout = setTimeout(() => search(), 500)
      return () => clearTimeout(timeout)
    } else {
      changeShow(false)
      setResult([])
    }
  }, [value, changeShow])


  return result
}


const SearchBar = (props) => {

  const { history } = props;
  const { manager } = useParams()
  const [show, setShow] = useState(false)
  const [value, setValue] = useState(null)
  const changeShow = useCallback((status) => setShow(status), [])

  const result = useSearch({value, changeShow})


  const getItems = useCallback((results) => {
    if (results) {
      return results.map((row, key) => {
        let type = row.type + 's';
        let href = '/' + manager +'/' + type + '/' + row.id;
        return (
          <DropdownItem
            key={key}
            className="w-100 d-flex"
            onClick={(e) => {
              e.preventDefault()
              history.replace(href)
              changeShow(false)
            }}
          >
            <div className="w-75 mr-2 text-truncate">
              <span>{row.full_name}</span>
            </div>
            <div className="w-25">
              {(row.type === 'inspector') ? <Badge color="info" className="w-20"> Müfəttiş </Badge> : <Badge color="warning" className="w-20">Abonent</Badge>}
            </div>
          </DropdownItem>
        );
      });
    }
    return null;
  }, [manager, history, changeShow]);

  return (
    <Form className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto">
      <FormGroup className="mb-0">
        <Dropdown toggle={(e) => e.preventDefault()}>
          <InputGroup className="input-group-alternative custom-navbar-search">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Axtar"
              type="text"
              className="form-control-sm"
              onChange={(e) => setValue(e.target.value)}
              value={value ?? ''}
              onClick={(e) => {
                e.preventDefault()
                if (result && result.length) changeShow(true)
              }}
            />
          </InputGroup>
          {result &&
            <DropdownMenu className={(show) ? "show custom-dropdown-menu" : "dropdown-menu-arrow custom-dropdown-menu"} right>
              {getItems(result)}
            </DropdownMenu> }
        </Dropdown>
      </FormGroup>
    </Form>
  );
}


export default SearchBar;
