import React, { useContext, useState } from "react"


const SocketContext = React.createContext({
  socket: null,
  setSocket: (socket) => {},
})


export const SocketProvider = (props) => {
  const [socket, setSocket] = useState(null)

  return (
    <SocketContext.Provider value={{ socket, setSocket }}>
      {props.children}
    </SocketContext.Provider>
  )
}

export const useSocket = () => useContext(SocketContext)

export default SocketContext
