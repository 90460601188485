import React, { Fragment, useEffect } from "react";

import BaseNavbar from "components/Navbars/BaseNavbar";
import Footer from "components/Footers/DefaultFooter";
import { useCompany } from "store/company-context";

import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';

import { strToUpper } from 'helpers/utils';
import { regionActions } from "store/slices/region-slice";
import { useDispatch } from "react-redux";

const useResetRegion = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(regionActions.resetState())
  }, [dispatch])
}


const Admin = (props) => {

  const { managements, companyName } = useCompany();

  useResetRegion()

  let lg = (managements.length < 5)? Math.floor(12 / managements.length): 4;
  let md = (managements.length < 5)? Math.floor(lg * 2): 6;

  const {history} = props;

  document.title = (companyName) ? strToUpper(companyName)  : "BAKU LIFT";

  const cards = managements.map((management, index) => {
    let className, href;
    switch (management.code) {
      case "901":
        className = 'bg-bank';
        href = '/baklift-1';
        break;
      case '902':
        className = 'bg-notification';
        href = '/baklift-2';
        break;
      case '903':
        className = 'bg-payment';
        href = '/baklift-3';
        break;
      case '904':
        className = 'bg-default';
        href = '/bma';
        break;
      case '905':
        className = 'bg-purple';
        href = '/temp';
        break;
      case '100':
        className = 'bg-bank';
        href = '/sumaks';
        break;
      default:
        break;
    }

    if (className && href) {
      className = "department-div shadow d-flex justify-content-center " + className;
      return (
        <Col md={md} lg={lg} key={index} onClick={() => history.push(href) }>
          <Card className={className}>
            <CardBody className="d-flex justify-content-center align-items-center">
              <h1 className="w-100 text-center text-white">{management.name}</h1>
            </CardBody>
          </Card>
        </Col>
      )
    }
    return null;
  });

  return (
    <Fragment>
      <BaseNavbar />
      <div className="container">
        <Row className="align-items-center justify-content-center vh-100 pt-100 pb-100">
          {cards}
        </Row>
      </div>
      <Footer className="footer" />
    </Fragment>
  );
}



export default Admin;
