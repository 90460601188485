import { useEffect } from "react";
import { useAuth } from "store/auth-context";
import { INDEX_API_TOTAL } from 'api/urls';
import { useDispatch } from "react-redux";
import { dashboardActions } from "store/slices/dashboard-slice";


export const useDashboardData = () => {
  const auth = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(INDEX_API_TOTAL, {
        method: "GET",
        headers: {
          'Authorization': 'Token ' + auth.token,
          'Content-Type': 'application/json',
        }
      })

      if(!response.ok) {
        if (response.status === 401) auth.logout()
        throw Error('status error: status=' + response.status)
      }

      const data = await response.json()
      dispatch(dashboardActions.setState(data))
    }

    fetchData()
  }, [auth, dispatch])

}
