import React, { Suspense, Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';

// import * as actions from './store/actions/index';
import Login from "./views/Login";
import Main from 'routes/Main';
import ReactNotification from 'react-notifications-component';
import { useAuth } from 'store/auth-context';
import { CompanyProvider } from 'store/company-context';
import { SocketProvider } from 'store/socket-context';


const App = () => {
  const auth = useAuth();

  return (
    <Fragment>
      <ReactNotification />
      <Suspense
        fallback={<p>Loading...</p>}
      >
        {auth.isLoggedIn ?
          <CompanyProvider>
            <SocketProvider>
              <Main />
            </SocketProvider>
          </CompanyProvider> :
          <Switch>
            <Route path="/auth/login" render={(props) => <Login {...props} />} />
            <Redirect from="/" to="/auth/login" />
          </Switch>}
      </Suspense>
    </Fragment>
  );
}

export default withRouter(App);
