import { useState, useEffect } from "react";
import { useAuth } from "store/auth-context";
import { LOGOUT } from "api/urls";

export const useLogout = () => {
  const [logout, setLogout] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (logout) {
      const fetchForLogout = async () => {
        const response = await fetch(LOGOUT, {
          method: 'POST',
          headers: {
            'Authorization': 'Token ' + auth.token,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          if(response.status !== 401 ) throw new Error(`Network response was not OK . Status = ${response.status}`);
        }
        setLogout(false);
        auth.logout();
      }

      fetchForLogout();
    }
  }, [logout, auth]);

  return setLogout
}
