import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useAuth } from "store/auth-context"
import { successNotfication, errorNotification } from "helpers/notification"
import { REGIONS_API } from "api/urls"
import { useDispatch } from "react-redux"
import { regionActions } from "store/slices/region-slice"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'




const RegionDeleteModal = ({ isOpen, toggle, region }) => {

  const [remove, setRemove] = useState(false)
  const history = useHistory()
  const {id, manager} = useParams()
  const auth = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    if(remove) {
      const deleteFetch = async () => {
        const response = await fetch(REGIONS_API + `${id}/`, {
          method: 'DELETE',
          headers: {
            'Authorization': 'Token ' + auth.token,
            'Content-Type': 'application/json',
          },
        })

        if(!response.ok) {
          if (response.status === 401) auth.logout()
          errorNotification()
          throw Error('status error: status=' + response.status)
        }
        successNotfication()
        history.push(`/${manager}/regions/`)
        dispatch(regionActions.resetState())
        setRemove(false)
      }

      deleteFetch()
    }
  }, [history, id, manager, remove, auth, dispatch])


  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
    >
      <ModalHeader toggle={toggle}>{region?.name}</ModalHeader>
      <ModalBody>
        <div className="py-2 text-center">
          <i className="fas fa-exclamation-triangle fa-3x"></i>
          <h3 className="mt-2 text-white">{region?.name} silmək isdədiyinizə əminsinizmi?</h3>
          <h3 className="mt-2 text-white"><b>Qeyd: </b> Bu rayon / küçəyə aid evlər ( binalar ) və abonentlər 'Digərləri' rayon / küçəsinə küçürüləcək. </h3>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-white btn-sm"
          color="default"
          onClick={() => setRemove(true)}
        >
          Bəli, silinsin
        </Button>
        {' '}
        <Button
          className="text-white ml-auto btn-sm"
          color="link"
          onClick={toggle}
        >
          Ləğv et
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RegionDeleteModal
