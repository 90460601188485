import { notificationOptions } from "./utils";
import { store } from 'react-notifications-component';



export const successNotfication = () => {
  store.addNotification({
    ...notificationOptions,
    title: "Uğurlu əməliyyat!",
    message: 'Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.',
    type: 'success',
  });
}


export const errorNotification = () => {
  store.addNotification({
    ...notificationOptions,
    title: "Uğursuz əməliyyat!",
    message: 'Xəta baş verdi.',
    type: 'danger',
  });
}
