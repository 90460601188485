import React, { useEffect, useCallback, useState } from "react"
import { PAYER_DETAIL } from "api/urls"
import { useCompany } from "store/company-context"

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input
} from "reactstrap"

import { successNotfication, errorNotification } from "helpers/notification"
import { decimalInTwoStep } from "shared/utility"


const EditDeptModal = props => {

  const {services} = useCompany()
  const [save, setSave] = useState(false)

  const {
    modal, modalToggle, payerId, onBtnDisabledEvent, btnDisabled, values, setValues, init
  } = props

  // const [values, setValues] = useState(debtData);

  const onInputChange = useCallback((input, value, values) => {
    const updatedValues = { ...values };
    updatedValues[input] = value;
    setValues(updatedValues);
  }, [setValues]);


  const getOptions = useCallback((selectedService) => {

    if (!services) return null;

    return services.map((service, i) => {
      if (selectedService && selectedService === service.id) {
        return <option value={service.id} defaultValue key={i}>{service.name}</option>
      }

      return <option value={service.id} key={i}>{service.name}</option>
    });

  }, [services]);


  const saveData = useCallback(async () => {
    const URL = PAYER_DETAIL + `${payerId}/debts/${values.id}/`;
    onBtnDisabledEvent(true);
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        service: values.service,
        amount: values.amount,
      })
    });

    if ([200, 201].includes(response.status)) {
      successNotfication();
      onBtnDisabledEvent(false);
      modalToggle();
      init();
    } else {
      errorNotification();
      onBtnDisabledEvent(false);
    }
  }, [payerId, onBtnDisabledEvent, modalToggle, init, values]);


  useEffect(() => {
    if(save) {
      saveData();
      setSave(false);
    }
  }, [save, saveData]);

  return (
    <Modal
      isOpen={modal}
      toggle={modalToggle}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={modalToggle}></ModalHeader>
      <ModalBody>
        <Card>
          <CardBody className="px-lg-5 py-lg-5">
            <FormGroup>
              <Label>Vergi növü *</Label>
              <Input
                placeholder="Vergi növü"
                type="select"
                className="form-control"
                onChange={(e) => onInputChange('service', parseInt(e.target.value), values)}
                value={(values.service) ? values.service : ''}
                required
              >
                {getOptions(values.service)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Borc *</Label>
              <Input
                placeholder="Borc"
                type="number"
                className="form-control"
                onChange={(e) => onInputChange('amount',  decimalInTwoStep(e.target.value), values)}
                value={values.amount ?? ''}
                required
              />
            </FormGroup>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button color="info" onClick={() => setSave(true)} disabled={btnDisabled}>Yadda saxla</Button>{' '}
        <Button className="ml-auto" color="link" onClick={modalToggle}>Ləğv et</Button>
      </ModalFooter>
    </Modal>
  );
}


export default EditDeptModal;
