import { useState, useEffect } from "react"
import { useAuth } from "store/auth-context"
import { useParams } from "react-router-dom"
import { INSPECTOR_DETAIL } from "api/urls"
import { useSelector } from "react-redux"


export const useInspectorDetail = () => {
  const auth = useAuth()
  const { id } = useParams()
  const [state, setState] = useState({
    id: null,
    fullName: null,
    inspectorImage: null,
    inspectorPhone: null,
  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(INSPECTOR_DETAIL + `${id}/`, {
        method: "GET",
        headers: {
          'Authorization': 'Token ' + auth.token,
          'Content-Type': 'application/json',
        }
      })

      if (!response.ok) {
        if (response.status === 401) auth.logout()
        throw Error('status error: status=' + response.status)
      }

      const data = await response.json()
      setState({
        id: data.id,
        fullName: data.full_name,
        inspectorImage: data.photo,
        inspectorPhone: data.phone,
      })
    }

    fetchData()
  }, [auth, id])

  return state
}



export const useInspectorData = ({
  page, excel, start, end, activePage, setExcel
}) => {
  const auth = useAuth()
  const { id } = useParams()
  const reset = useSelector(state => state.reset.reset)

  const [state, setState] = useState({
    count: null,
    pages: null,
    results: null,
  })


  useEffect(() => {
    const fetchData = async () => {
      const url = new URL(INSPECTOR_DETAIL + `${id}/${activePage}/`)
      if (page) {
        url.searchParams.set('page', page)
      }
      if (start) {
        url.searchParams.set('start', start)
      }
      if (end) {
        url.searchParams.set('end', end)
      }
      if (excel) {
        url.searchParams.set('export', 'true')
      }

      const headers = {
        'Authorization': 'Token ' + auth.token,
      }
      if (!excel) {
        headers['Content-Type'] = 'application/json'
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      })

      if (!response.ok) {
        if (response.status === 401) auth.logout()
        throw Error('status error: status=' + response.status)
      }

      if (!excel) {
        const data = await response.json()
        setState({
          count: data.count,
          pages: data.pages,
          results: data.results,
          total_amount: data.total_amount,
        })
      } else {
        const blob = await response.blob()
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `Hesabat_${id}_${new Date().toJSON().slice(0, 10)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setExcel(false)
      }
    }

    const timeOut = setTimeout(() => fetchData(), [300])
    return () => clearTimeout(timeOut)
  }, [
    auth, id, page, excel, start, end, activePage, setExcel, reset
  ])


  return state
}
