import React, { useCallback } from 'react';


const Header = React.memo(props => {

  const {
    inspectorImage, fullName
  } = props;


  const shortName = useCallback(() => {
    let name = '';
    if (fullName) {
      const fn = fullName.split(" ");
      for (let i = 0; i < fn.length; i++) {
        if (i < 2) {
          name += fn[i].toUpperCase().charAt(0);
        }
      }
    }
    return name;
  }, [fullName]);


  return (
    <h2 className="mb-0 d-flex" >
      {(inspectorImage) ? <span className="avatar avatar-sm rounded-circle">
        <img
          alt="..."
          src={inspectorImage}
        />
      </span> :
        <span className="profile-rounded-circle">
          {shortName()}
        </span>}
      <span className="profile-circle-name">{fullName}</span>
    </h2>
  );
});



export default Header;
