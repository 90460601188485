import React, { useEffect, useCallback, useState } from "react"
import { useLocation, Link } from "react-router-dom"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useCompany } from "store/company-context";
import { useAuth } from "store/auth-context";
import {
  Card,
  CardHeader,
  Container,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  NavLink,
  Label,
  FormGroup,
  Form,
  //FormFeedback
} from "reactstrap";

import Address from 'components/Payer/Address';

import PhoneInput from 'react-phone-number-input/input';
import DebtForm from "components/Payer/Debt";
import { successNotfication, errorNotification } from "helpers/notification";
import { checkPermission, CREATE } from "helpers/permission";


import addPayer from "store/actions/addPayer";
// import { useForm } from "react-hook-form";

const AddPayer = props => {

  const location = useLocation()
  const auth = useAuth()
  const { services, permissions } = useCompany()
  const [mkis, setMkis] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [address, setAddress] = useState(null);
  const [phone, setPhone] = useState(null);
  const [{region, house}, setRegionHouse] = useState({
    region: null,
    house: null,
  })
  const [flat, setFlat] = useState(null);
  const [livedPeople, setLivedPeople] = useState(null);
  const [note, setNote] = useState(null);
  const [btnDisabled, onBtnDisabledEvent] = useState(false);
  const [savePayer, onReadyForFetch] = useState(false);
  const [debts, onUpdateDebts] = useState([]);

  const { history } = props;


  useEffect(() => {
    if (savePayer) {
      onBtnDisabledEvent(true);
      addPayer(auth, {
        payer: {
          full_name: fullName,
          mkis,
          address,
          phone,
          region,
          house,
          flat,
          note,
          lived_people: livedPeople,
        },
        debt: debts,
      }).then(status => {
        onReadyForFetch(false);
        onBtnDisabledEvent(false);
        successNotfication();
      }).catch(err => {
        onReadyForFetch(false);
        onBtnDisabledEvent(false);
        errorNotification();
      });

    }
  }, [
    savePayer, fullName, mkis, address,
    phone, region, house, flat, debts, auth,
    livedPeople, note
  ]);

  if (permissions.length > 0 && !checkPermission(permissions, CREATE)) {
    history.replace('/')
  }

  const updateDebt = useCallback((id, debt) => {
    const updatedDebts = [...debts];
    updatedDebts[id].service = debt.service;
    updatedDebts[id].amount = debt.amount;
    onUpdateDebts(updatedDebts);
  }, [debts, onUpdateDebts]);


  const deleteDebt = useCallback(id => {
    const updatedDebts = [...debts];
    updatedDebts.splice(id, 1);
    onUpdateDebts(updatedDebts);
  }, [debts, onUpdateDebts]);


  const getDebtsForm = useCallback(() => {
    if (!services) return null;
    return debts.map((debt, i) => {
      return <DebtForm
        key={i}
        debtID={i}
        services={services}
        {...debt}
        onChange={(id, debt) => updateDebt(id, debt)}
        onDelete={() => deleteDebt(i)}
      />
    });
  }, [services, debts, updateDebt, deleteDebt]);


  const newDebt = useCallback(() => {
    const updatedDebts = [...debts];
    updatedDebts.push({
      service: null,
      amount: 0,
    });
    onUpdateDebts(updatedDebts);
  }, [onUpdateDebts, debts]);

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault();
    if( e.target.checkValidity()) {
      onReadyForFetch(true);
    } else {
      e.target.reportValidity()
    }
  }, [onReadyForFetch]);


  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <Link
          color="inherit"
          to={location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1)}
          className="p-0 nav-link"
        >
          Abonentlər
        </Link>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          Yeni Abonent
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <h2 className="mb-0 d-flex">Yeni Abonent</h2>
        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Form onSubmit={handleOnSubmit}>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Ad, Soyad, Ata adı *</Label>
                  <Input
                    placeholder="Ad, Soyad, Ata adı"
                    type="text"
                    className="form-control form-control-sm"
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName ?? ''}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>MKIS nömrəsi *</Label>
                  <Input
                    placeholder="MKIS nömrəsi"
                    type="text"
                    className="form-control form-control-sm"
                    onChange={(e) => setMkis(e.target.value)}
                    value={mkis ?? ''}
                    maxLength='3'
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Ünvan *</Label>
                  <Input
                    placeholder="Ünvan"
                    type="textarea" row={2}
                    className="form-control form-control-sm"
                    value={address ?? ''}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                  {/* {errors.addressRequired && <FormFeedback>Ünvan daxil edin!</FormFeedback>} */}
                </FormGroup>
              </Col>

              <Address region={region} house={house} setRegionHouse={setRegionHouse} method='create' />

              <Col md="4">
                <FormGroup>
                  <Label>Mənzil</Label>
                  <Input
                    placeholder="Mənzil"
                    type="text"
                    className="form-control form-control-sm"
                    value={flat ?? ''}
                    onChange={(e) => setFlat(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Əlaqə nömrəsi</Label>
                  <PhoneInput
                    placeholder="Əlaqə nömrəsi"
                    country="AZ"
                    defaultCountry="AZ"
                    className="form-control form-control-sm"
                    international
                    withCountryCallingCode
                    value={phone ?? ''}
                    onChange={(p) => setPhone(p)}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Ailə tərkibi</Label>
                  <Input
                    placeholder="Ailə tərkibi"
                    type="number"
                    className="form-control form-control-sm"
                    value={livedPeople ?? ''}
                    onChange={(e) => setLivedPeople(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Qeyd</Label>
                  <Input
                    placeholder="Qeyd"
                    type="textarea"
                    className="form-control form-control-sm"
                    value={note ?? ''}
                    onChange={(e) => setNote(e.target.value)}
                    row={2}
                  />
                </FormGroup>
              </Col>

            </Row>

            <hr className="my-4" />

            <h6 className="heading-small text-muted mb-4">
              Borc məlumatları
            </h6>
            <Row>
              {getDebtsForm()}
            </Row>
            <Button className="btn"
              color="info"
              onClick={newDebt}
              disabled={btnDisabled}
            >
              <i className="fa fa-plus mr-2"></i> Borc məlumatı əlavə et
            </Button>
            <Button className="btn custom-btn-style"
              color="info"
              type="submit"
              disabled={btnDisabled}
            >
              Yadda saxla
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}



export default AddPayer;
