import { useEffect } from "react"
import { useAuth } from "store/auth-context"
import { useCompany } from "store/company-context"
import { COMPANY, SERVICES_API, MANAGEMENTS, PERMISSIONS } from "api/urls";


export const useCompanyData = () => {
  const auth = useAuth()
  const { setData } = useCompany()
  useEffect(() => {
    const fetchCompany = async () => {
      const options = {
        method: "GET",
        headers: {
          'Authorization': 'Token ' + auth.token,
          'Content-Type': 'application/json',
        }
      }
      const [
        companyResponse, managementsResponse, permissionsResponse, servicesResponse
      ] = await Promise.all([
        fetch(COMPANY, options),
        fetch(MANAGEMENTS, options),
        fetch(PERMISSIONS, options),
        fetch(SERVICES_API, options)
      ])

      if(
        !companyResponse.ok ||
        !managementsResponse.ok ||
        !permissionsResponse.ok ||
        !servicesResponse.ok
      ) {
        if(companyResponse.status === 401) {
          auth.logout()
          return
        }
        throw Error('Big fetch error')
      }

      const [company, managements, permissions, services] = await Promise.all([
        companyResponse.json(),
        managementsResponse.json(),
        permissionsResponse.json(),
        servicesResponse.json(),
      ])

      setData({
        companyId: company.id,
        companyName: company.name,
        companyPhone: company.phone,
        companyLogo: company.logo,
        username: company.username,
        userFullName: company.full_name,
        userPhoto: company.user_photo,
        services,
        managements,
        permissions
      })
    }

    fetchCompany()

  }, [auth, setData])

  return
}
