export const EDIT = 'edit';
export const CREATE = 'create';
export const DELETE = 'delete';
export const LOG = 'log';

export const checkPermission = (permissions, permission) => {
  for ( let i in permissions ) {
    if (permissions[i].name === permission) return true;
  }

  return false;
}
