import { ADD_PAYER_POP_API } from "api/urls";

const addPayer = async (auth, postData) => {
  const URL = ADD_PAYER_POP_API;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      'Authorization': 'Token ' + auth.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData)
  })

  if (!response.ok) {
    if(response.status === 401) auth.logout()
    throw Error('An error occured')
  }

  return response.status
}


export default addPayer;
