
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useCompany } from "store/company-context";
import { useLogout } from "hooks/logout";
import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Media,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

import { strToUpper, shortName } from 'helpers/utils';

const BaseNavbar = (props) => {
  const { companyLogo, userFullName, username, userPhoto, companyName } = useCompany()
  const userName = userFullName ?? username

  const { departmentName } = props

  const logout = useLogout()

  const logo = {
    innerLink: "/",
    imgSrc: (companyLogo) ? companyLogo : "",
    imgAlt: "...",
  }

  let navbarBrandProps;

  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }


  return (
    <>
      <Navbar className="navbar-nav navbar-light bg-white fixed-top custom-navbar-style" expand="md" id="navbar-main">
        <div className="custom-navbar container-fluid">
          {logo ? (
            <NavbarBrand className="pt-0 ml-2 mr-auto" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img d-inline"
                src={logo.imgSrc}
                width={"30px"}
              />
              <h3 className="pl-3 d-inline">{strToUpper(companyName)}</h3>
              {departmentName &&
                <Fragment><span className="ml-3 mr-3"><i className="fas fa-angle-right" /></span>
                  <h3 className="d-inline">{strToUpper(departmentName)}</h3>
                </Fragment>
              }
            </NavbarBrand>
          ) : null}


          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="mr-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {strToUpper(userName)}
                    </span>
                  </Media>
                  {(userPhoto) ? <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={userPhoto}
                    />
                  </span> :
                    <span className="profile-rounded-circle text-dark">
                      {shortName(userName)}
                    </span>
                  }
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem href="#" onClick={(e) => {
                  e.preventDefault();
                  logout(true);
                }}>
                  <i className="fas fa-sign-out-alt" />
                  <span>Çıxış</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
      </Navbar>
    </>
  );
};



export default BaseNavbar;
