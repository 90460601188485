import React, { useCallback, useState } from "react"
import DebtForm from './Debt'
import { DEBT } from 'api/urls'
import { notificationOptions } from 'helpers/utils'
import { store } from 'react-notifications-component'
import { useCompany } from "store/company-context"
import {
  Row, Button
} from "reactstrap"


const AddDebt = props => {

  const {services} = useCompany()
  const {init, payerId } = props
  const [debts, updateDebts] = useState([])
  const [btnDisabled, setBtnDisabled] = useState(false)


  const newDebt = useCallback(() => {
    const updatedDebts = [...debts];
    updatedDebts.push({
      service: null,
      amount: 0,
    });
    updateDebts(updatedDebts);
  }, [updateDebts, debts])


  const updateDebt = useCallback((id, debt) => {
    const updatedDebts = [...debts];
    updatedDebts[id].service = debt.service;
    updatedDebts[id].amount = debt.amount;
    updateDebts(updatedDebts);
  }, [debts, updateDebts]);


  const deleteDebt = useCallback(id => {
    const updatedDebts = [...debts];
    updatedDebts.splice(id, 1);
    updateDebts(updatedDebts);
  }, [debts, updateDebts]);

  const debtsHTML = debts.map((debt, i) => {
    return <DebtForm
      key={i}
      debtID={i}
      services={services}
      {...debt}
      onChange={(id, debt) => updateDebt(id, debt)}
      onDelete={() => deleteDebt(i)}
    />
  });


  const saveDebts = useCallback(async (debts) => {
    const URL = DEBT + `${payerId}/`;
    setBtnDisabled(true);
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(debts)
    });

    if ([200, 201].includes(response.status)) {
      store.addNotification({
        ...notificationOptions,
        title: "Uğurlu əməliyyat!",
        message: 'Dəyişikliklər müvəffəqiyyətlə yadda saxlanıldı.',
        type: 'success',
      });
      setBtnDisabled(false);
      updateDebts([]);
      init();
    } else {
      store.addNotification({
        ...notificationOptions,
        title: "Uğursuz əməliyyat!",
        message: 'Xəta baş verdi..',
        type: 'danger',
      });
      setBtnDisabled(false);
    }
  }, [payerId, updateDebts, init]);


  return (
    <>
      <Row>
        {debtsHTML}
      </Row>
      <Button className="btn"
        color="info"
        onClick={newDebt}
        disabled={btnDisabled}
      >
        <i className="fa fa-plus mr-2"></i> Borc məlumatı əlavə et
      </Button>
      {(debts.length) ?
        <Button className="btn"
          color="info"
          onClick={() => saveDebts(debts)}
          disabled={btnDisabled}
        >
          Yadda saxla
        </Button> : null}
    </>
  );
}


export default AddDebt;
