import { createSlice } from "@reduxjs/toolkit";


const regionSlice = createSlice({
  name: 'region',
  initialState: {
    regions: [],
    btnDisabled: false,
    resetHouses: false
  },
  reducers: {
    setState(state, action){
      state.regions = action.payload
    },
    update(state, action){
      const index = state.regions.findIndex(region => region.id === action.payload.id)
      if (index !== -1) {
        state.regions[index].name = action.payload.name
      }
    },
    toggleBtn(state){
      state.btnDisabled = !state.btnDisabled
    },
    resetState(state) {
      state.regions = []
    },
    resetHouses(state) {
      state.resetHouses = !state.resetHouses
    }
  }
})


export const regionActions = regionSlice.actions
const regionReducer = regionSlice.reducer
export default regionReducer
