import React, { useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useUpdateRegion, useRegion } from "hooks/address"
import { useDispatch, useSelector } from "react-redux"
import { regionActions } from "store/slices/region-slice"
import {
  Container,
  Card,
  NavLink,
  CardHeader,
  Form,
  Input,
  Row,
  Col,
  Button,
  CardBody
} from 'reactstrap'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import House from "components/region/House"
import { Link } from "react-router-dom"
import { useCompany } from "store/company-context"
import { checkPermission, EDIT, DELETE } from "helpers/permission"
import RegionDeleteModal from "components/region/RegionDeleteModal"

const RegionDetail = () => {
  const { id } = useParams()
  const btnDisabled = useSelector(state => state.region.btnDisabled)
  const location = useLocation()
  const regions = useRegion()
  const region = regions.find(item => parseInt(item.id) === parseInt(id))
  const [name, setName] = useState(region?.name)
  const dispatch = useDispatch()
  const {permissions} = useCompany()
  const [modal, showModal] = useState(false)

  useUpdateRegion({
    id: region?.id,
    name: region?.name
  })

  const handleOnSubmit = (e) => {
    e.preventDefault()
    dispatch(regionActions.toggleBtn())
    dispatch(regionActions.update({
      name: name,
      id,
    }))
  }
  const editPermission = checkPermission(permissions, EDIT)
  let disabledForm = true
  if (editPermission) {
    disabledForm = region?.name === 'Digərləri'
  }

  const toggleModal = () => {
    if(checkPermission(permissions, DELETE)) {
      showModal(!modal)
    }
  }

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <Link
          color="inherit"
          to={location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1)}
          className="p-0 nav-link"
        >
          Rayon - Küçə
        </Link>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          {region?.name}
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <Row>
            <Col xs="8">
              <h2 className="mb-0 d-flex">{region?.name}</h2>
            </Col>
            {checkPermission(permissions, DELETE) && <Col xs="4">
              <Button
                className="ustom-btn-style btn-sm float-right"
                color="danger"
                type="button"
                onClick={(e) => toggleModal()}
              >
                Sil
              </Button>
            </Col>}
          </Row>
        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Form onSubmit={handleOnSubmit}>
            <Row>
              <Col md="2" xl='1' className="mb-2">Adı *:</Col>
              <Col md="4" xl='3' className="mb-3">
                <Input
                  placeholder="Adı"
                  type="text"
                  className="form-control form-control-sm"
                  onChange={(e) => setName(e.target.value)}
                  value={(name) ? name : region?.name ?? ''}
                  required
                  disabled={disabledForm}
                />
              </Col>
              <Col md="4">
                <Button className="btn custom-btn-style btn-sm"
                  color="info"
                  type="submit"
                  disabled={btnDisabled || disabledForm}
                >
                  Yadda saxla
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
        {modal && <RegionDeleteModal
          region={region}
          isOpen={modal}
          toggle={toggleModal}
        />}
      </Card>
      <House id={id} />
    </Container>
  )
}

export default RegionDetail
