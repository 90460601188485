import React, { useCallback, useState } from "react"

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  FormGroup,
  Form,
  Label,
  Input
} from "reactstrap"
import { useParams } from "react-router-dom"
import { useRegion, useSaveHouse } from "hooks/address"


const HouseAddModal = ({ isOpen, toggle, resetState }) => {

  const regions = useRegion()
  const {id} = useParams()
  const [name, setName] = useState(undefined)
  const [save, setSave] = useState(false)

  useSaveHouse(save, name, id, resetState)

  const getOptions = useCallback((list) => {
    return list.map((item, i) => {
      return (
        <option
          value={item.id}
          key={i}
        >
          {item.name}
        </option>
      )
    });
  }, [])

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault()
    if (e.target.checkValidity()) setSave(true)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={toggle}>Yeni ev (bina)</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleOnSubmit} id='region-modal-form'>
          <FormGroup>
            <Label>Rayon / küçə *</Label>
            <Input
              type="select"
              value={id}
              className="custom-form-control-sm"
              required
              disabled={true}
            >
              {getOptions(regions)}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Adı *</Label>
            <Input
              placeholder="Adı"
              type="text"
              className="form-control form-control-sm"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="info"
          disabled={save}
          type='submit'
          form="region-modal-form"
          className="btn-sm"
        >
          Yadda saxla
        </Button>
        {' '}
        <Button
          className="ml-auto btn-sm"
          color="link"
          onClick={toggle}
        >
          Ləğv et
        </Button>
      </ModalFooter>
    </Modal>
  )
}


export default HouseAddModal
