
import React, { useCallback, Fragment } from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import FinanceNavbar from "components/Navbars/FinanceNavbar";
import FinanceFooter from "components/Footers/FinanceFooter";
import Sidebar from "components/Sidebar/Sidebar";
import { useSelector } from 'react-redux';
// import { setSocket } from 'helpers/setSocket';
// For routes
import Dashboard from "views/finance/Dashboard";
import Payer from "views/finance/payer";
import PayerDetail from "views/finance/payer/detail";
import Inspector from "views/finance/inspector";
import InspectorDetail from "views/finance/inspector/detail";
// import ActivityLog from "views/finance/ActivityLog";
import AddPayer from "views/finance/payer/add";
import { strToUpper, getManagement } from 'helpers/utils';
// import { checkPermission, CREATE } from "helpers/permission";
import Regions from "views/finance/region";
import RegionDetail from "views/finance/region/detail";
import BankBill from "views/finance/bankbill";
import DeleteBill from "views/finance/deletebill";
import { useCompany } from "store/company-context";

import routes from "routes";

import { useCookies } from 'react-cookie';
import { useUpdater } from "hooks/socket";


const Routers = React.memo((props) => {
  const mainContent = React.useRef(null);
  // const location = useLocation();
  const { manager } = useParams();

  const [cookies, setCookie] = useCookies(['activeManagement']);

  const {
    companyName,
    companyLogo,
    managements,
    // permissions
  } = useCompany();
  const isOpen = useSelector(state => state.navbar.isOpen);
  const management = getManagement(managements, manager);

  useUpdater(management?.id)

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, []);

  const getBrandText = useCallback((path, routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        path.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  }, []);

  document.title = (companyName) ? strToUpper(companyName + ` | ${management ? management.name : ''}`) : "BAKU LIFT";

  let liftRedirect;

  if (management) {
    if (!cookies.activeManagement || parseInt(cookies.activeManagement) !== management.id) {
      setCookie('activeManagement', management.id, { path: '/' });
    }
    switch (management.code) {
      case "901":
        liftRedirect = <Redirect from='*' to='/baklift-1/dashboard' />;
        break;
      case "902":
        liftRedirect = <Redirect from='*' to='/baklift-2/dashboard' />;
        break;

      case "903":
        liftRedirect = <Redirect from='*' to='/baklift-3/dashboard' />;
        break;
      case "904":
        liftRedirect = <Redirect from='*' to='/bma/dashboard' />;
        break;
      case "905":
        liftRedirect = <Redirect from='*' to='/temp/dashboard' />;
        break;
      case "100":
        liftRedirect = <Redirect from='*' to='/sumaks/dashboard' />;
        break;
      default:
        return null;
    }
  }

  return (
    <Fragment>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/",
          imgSrc: (companyLogo) ? companyLogo : "",
          imgAlt: "...",
        }}
        companyName={companyName}
        management={management}
      />
      <div className={(isOpen) ? "main-content" : "main-content custom-main-content"} ref={mainContent} >
        <FinanceNavbar
          {...props}
          brandText={getBrandText(props.location.pathname, routes)}
          logo={{
            innerLink: "/",
            imgSrc: (companyLogo) ? companyLogo : "",
            imgAlt: "...",
          }}
          companyName={companyName}
          management={management}
        />
        <Switch>
          <Route exact path='/:manager/dashboard' render={(props) => <Dashboard {...props} />} />

          <Route exact path='/:manager/payers' render={(props) => <Payer {...props} />} />
          <Route exact path='/:manager/payers/add' s render={(props) => <AddPayer {...props} />} />
          <Route exact path='/:manager/payers/:id' render={(props) => <PayerDetail {...props} />} />
          <Route exact path='/:manager/inspectors' render={(props) => <Inspector {...props} />} />
          <Route path='/:manager/inspectors/:id' render={(props) => <InspectorDetail {...props} />} />
          {/* <Route path='/:manager/history' render={(props) => <ActivityLog {...props} />} /> */}
          <Route exact path='/:manager/regions' render={(props) => <Regions {...props} />} />
          <Route exact path='/:manager/regions/:id' render={(props) => <RegionDetail {...props} />} />
          <Route exact path='/:manager/bankbills' render={(props) => <BankBill {...props} />} />
          <Route exact path='/:manager/deletebills' render={(props) => <DeleteBill {...props} />} />
          {liftRedirect}
        </Switch>
        <FinanceFooter />
      </div>
    </Fragment>
  );
});


export default Routers;
