import React, { useEffect, useState, useCallback } from "react";
import { DELETE_BILL_API } from "api/urls";
import { useLocation } from "react-router-dom";
import DatePicker from 'components/DatePicker';
// import { shortName } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';
import { Pagination } from '@material-ui/lab'
import {
    Card,
    CardHeader,
    CardFooter,
    // Media,
    Table,
    Container,
    Button,
    Input,
    Row,
    Col,
    CardBody,
    NavLink,
} from "reactstrap";


const useDeleteBillList = ({ page, start, end, Note, Inspector, billId, createdDate, excel, changeExcel }) => {
    const [count, setCount] = useState(null);
    const [pages, setPages] = useState(null);
    const [results, setResults] = useState([]);

    useEffect(() => {
        let url = DELETE_BILL_API;

        if (Note) {
            url += `&note=${Note}`;
        }
        if (Inspector) {
            url += `&inspector=${Inspector}`;
        }
        if (billId) {
            url += `&bill_id=${billId}`;
        }
        if (createdDate) {
            url += `&created_at=${createdDate}`;

        }
        if (start) {
            url += `&start=${start}`;
        }
        if (end) {
            url += `&end=${end}`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (excel) {
            url += '&export=true'
        }


        const getBankItems = async () => {
            let headers
            if (!excel) {
                headers = {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                }
            } else {
                headers = {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                }
            }

            const response = await fetch(url, {
                method: "GET",
                headers: headers
            })

            if (!excel) {
                const data = await response.json()
                setCount(data.count)
                setPages(data.pages)
                setResults(data.results)
            } else {
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Hesabat_bank_${new Date().toJSON().slice(0, 10)}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                changeExcel(false)
            }
        }

        const timeout = setTimeout(() => {
            getBankItems()
        }, 500)

        return () => clearTimeout(timeout)

    }, [page, start, end, Note, Inspector, billId, createdDate, excel, changeExcel])

    return {
        count,
        pages,
        results
    }
}



const BankBill = (props) => {

    const location = useLocation();
    const [page, setPage] = useState(1);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [Note, setNote] = useState(null);
    const [Inspector, setInspector] = useState(null);
    const [billId, setbillId] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [excel, setExcel] = useState(false)

    const changeExcel = useCallback((status) => {
        setExcel(status)
    }, [])



    const { count, pages, results } = useDeleteBillList({ page, start, end, Note, Inspector, billId, createdDate, excel, changeExcel });

    const { history } = props;

    const handleRowClick = useCallback((id) => {
        let url = (location.pathname.slice(-1) === '/') ? `${location.pathname}${id}` : `${location.pathname}/${id}`;
        history.replace(url);
    }, [history, location.pathname]);


    const getRows = useCallback((results = null) => {
        if (results) {
            return results.map((row, key) => {
                return (
                    <tr key={key} onClick={() => { handleRowClick(row.id) }}>
                        {/* <td className="text-truncate">
                            <Media className="align-items-center">
                                <Media className="d-lg-block pr-3">
                                    {(row.photo) ? <span className="avatar avatar-sm rounded-circle">
                                        <img
                                            alt="..."
                                            src={row.photo}
                                        />
                                    </span> :
                                        <span className="profile-rounded-circle profile-rounded-circle-sm">
                                            {shortName(row.full_name)}
                                        </span>
                                    }
                                </Media>
                                <Media className="d-lg-block">
                                    <span className="mb-0">
                                        {row.full_name}
                                    </span>
                                </Media>
                            </Media>
                        </td> */}
                        <td className="text-truncate">{row.note}</td>
                        <td className="text-truncate">{row.inspector}</td>
                        <td className="text-truncate">{row.bill_id}</td>
                        <td className="text-truncate">{row.created_at}</td>
                    </tr>
                );
            });
        }
        return null;
    }, [handleRowClick]);

    const startChange = useCallback((value) => {
        if (value instanceof moment) {
            setStart(value.format('YYYY-MM-DD'));
        } else if (!value) {
            setStart(null);
        }
    }, []);

    const endChange = useCallback((value) => {
        if (value instanceof moment) {
            setEnd(value.format('YYYY-MM-DD'));
        } else if (!value) {
            setEnd(null);
        }
    }, []);


    return (
        <Container className="custom-margin-top" fluid>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
                <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
                    Silinmiş Qəbzlər
                </NavLink>
            </Breadcrumbs>
            <Card className="shadow mb-5">
                <CardHeader className="border-0">

                    <Row>
                        <Col>
                            <h3 className="mb-0 custom-card-header">Silinmiş Qəbzlər</h3>
                        </Col>
                        <Col>
                            <Button className="btn float-right"
                                color="success"
                                onClick={(e) => setExcel(true)}
                                disabled={excel}
                            >
                                Excel <i className="ml-1 fas fa-file-download"></i>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <hr className="my-1" />
                <CardBody>
                    <Row>
                        <Col md="7" >
                            <DatePicker start={start} end={end} onStartChange={startChange} onEndChange={endChange} />
                        </Col>
                    </Row>
                    {/* <ul className="inspector-tabset mt-3">
                        <li className="inspector-tabset-tab">
                            <span className="tab">
                                YIĞIM CƏMİ : {11}
                            </span>
                        </li>
                    </ul> */}

                    <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{count ?? 0} Nəticə</span>
                    <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
                        <thead className="thead-light">
                            <tr>

                                <th scope="col">QEYD</th>
                                <th scope="col">İNSPEKTOR</th>
                                <th scope="col">QƏBZ №</th>
                                <th scope="col">YARANMA TARİXİ</th>

                            </tr>
                            <tr>

                                <th scope="col">
                                    <Input
                                        placeholder="Qeyd"
                                        type="text"
                                        value={Note ?? ''}
                                        onChange={(e) => {
                                            setNote(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                        className="form-control-sm"
                                    />
                                </th>
                                <th scope="col">
                                    <Input
                                        placeholder="İnspektor"
                                        type="text"
                                        value={Inspector ?? ''}
                                        onChange={(e) => {
                                            setInspector(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                        className="form-control-sm"
                                    />
                                </th>

                                <th scope="col">
                                    <Input
                                        placeholder="Qəbz №"
                                        type="text"
                                        className="form-control-sm"
                                        disabled
                                        value={billId ?? ''}
                                        onChange={(e) => {
                                            setbillId(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                    />
                                </th>

                                <th scope="col">
                                    <Input
                                        placeholder="Yaranma tarixi"
                                        type="text"
                                        className="form-control-sm "
                                        disabled
                                        value={createdDate ?? ''}
                                        onChange={(e) => {
                                            setCreatedDate(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {getRows(results)}
                        </tbody>
                    </Table>
                </CardBody>
                {(results) ?
                    <CardFooter className="py-4">
                        <nav aria-label="...">
                            <Pagination
                                count={pages}
                                page={page}
                                onChange={(_, page) => {
                                    setPage(page)
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    })
                                }}
                                className="pagination justify-content-end mb-0"
                                color="primary"
                            />
                        </nav>
                    </CardFooter> : null}
            </Card>
        </Container>
    );
}


export default BankBill;
