
import React from "react";
// reactstrap components
import { useCompany } from "store/company-context";
import {
  Card,
  CardBody,
  Container,
} from "reactstrap";

import { getManagement } from 'helpers/utils';

// core components

import BarData from 'components/charts/BarData';
// import PieData from 'components/charts/PieData';
import Meter from 'components/index/Meter';
import { useParams } from "react-router-dom";

const Dashboard = () => {
  const { managements } = useCompany();
  const { manager } = useParams();
  const management = getManagement(managements, manager);

  if (!management) {
    return null;
  }

  return (
    <Container className="custom-margin-top" fluid>
      <Card className="custom-card-style mb-5">
        <CardBody>
          <Meter />
          <BarData header="Hesabat" />
        </CardBody>
      </Card>
    </Container>
  );
};



export default Dashboard;
