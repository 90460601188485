
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  // Nav,
  // NavItem,
  // NavLink
} from "reactstrap";

const Footer = () => {
  return (
    <footer className="bg-white custom-footer mt-auto mb-0">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © {new Date().getFullYear()}{" "}
              <span className="font-weight-bold ml-1">
                ERP
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
