import { useDeleteBill } from "hooks/bill"
import React, { useCallback, useState } from "react"

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col
} from "reactstrap"
// import { useCompany } from "store/company-context"
// import { useRegion, useUpdateHouse, useDeleteHouse } from "hooks/address"
// import { useParams } from "react-router-dom"

const options = [
  'Yoxlamaq üçün vurulub',
  'Səhvən vurulub',
  'Çek sistemə 2 və daha artıq düşüb',
]


const BillDeleteModal = ({ bill, isOpen, toggle }) => {
  const [option, setOption] = useState(undefined)
  const [other, setOther] = useState(undefined)
  const [remove, setRemove] = useState(false)

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault()
    if (e.target.checkValidity()) setRemove(true)
  }, [])

  let reason

  if(option !== undefined) {
    const index = parseInt(option)
    if (index !== -1) {
      reason = options[index]
    } else {
      reason = other
    }
  }

  useDeleteBill(remove, bill?.id, reason, toggle)

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      // contentClassName="bg-gradient-danger"
    >
      <ModalHeader toggle={toggle}>QƏBZ №: {bill.id}</ModalHeader>
      <ModalBody>
        <Form id='delete-bill-form' onSubmit={handleOnSubmit}>
          <Row>
            <Col md='6'>
              <FormGroup>
                <Label>Abonent kodu</Label>
                <Input
                  type="text"
                  className="form-control form-control-sm"
                  value={bill.member_id}
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <Label>Ad, Soyad, Ata adı</Label>
                <Input
                  type="text"
                  className="form-control form-control-sm"
                  value={bill.full_name}
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <Label>Məbləğ</Label>
                <Input
                  type="text"
                  className="form-control form-control-sm"
                  value={bill.amount + ' ₼'}
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <Label>Tarix</Label>
                <Input
                  type="text"
                  className="form-control form-control-sm"
                  value={bill.date}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>Səbəb seçin *</Label>
            <div className="custom-control custom-radio mb-3">
              <input
                type='radio'
                className="custom-control-input"
                name="reason"
                id="reason1"
                value="0"
                onChange={e => setOption(e.target.value)}
              />
              <label
                className="custom-control-label"
                htmlFor="reason1"
              >
                Yoxlamaq üçün vurulub
              </label>
            </div>
            <div className="custom-control custom-radio mb-3">
              <input
                type='radio'
                className="custom-control-input"
                name="reason"
                id="reason2"
                onChange={e => setOption(e.target.value)}
                value="1"
              />
              <label
                className="custom-control-label"
                htmlFor="reason2"
              >
                Səhvən vurulub
              </label>
            </div>
            <div className="custom-control custom-radio mb-3">
              <input
                type='radio'
                className="custom-control-input"
                name="reason"
                id="reason3"
                onChange={e => setOption(e.target.value)}
                value="2"
              />
              <label
                className="custom-control-label"
                htmlFor="reason3"
              >
                Çek sistemə 2 və daha artıq düşüb
              </label>
            </div>
            <div className="custom-control custom-radio mb-3">
              <input
                type='radio'
                className="custom-control-input"
                name="reason"
                id="reason4"
                onChange={e => setOption(e.target.value)}
                value="-1"
              />
              <label
                className="custom-control-label w-100"
                htmlFor="reason4"
              >
                <Row>
                  <Col xs="3" xl="2">Digər: </Col>
                  <Col xs="9" xl="10">
                    <Input
                      type="textarea"
                      row={2}
                      className="form-control form-control-sm w-100"
                      onChange={e => setOther(e.target.value)}
                    />
                  </Col>
                </Row>
              </label>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          className="btn-sm"
          type="submit"
          form="delete-bill-form"
          disabled={remove || (!reason || reason === '')}
        >
          Sil
        </Button>
        {' '}
        <Button
          className="ml-auto btn-sm"
          color="secondary"
          onClick={toggle}
        >
          Ləğv et
        </Button>
      </ModalFooter>
    </Modal>
  )
}


export default BillDeleteModal
