import { createSlice } from "@reduxjs/toolkit";


const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    isOpen: true,
  },
  reducers: {
    toggle(state){
      state.isOpen = !state.isOpen
    }
  }
})


export const navbarActions = navbarSlice.actions
const navbarReducer = navbarSlice.reducer
export default navbarReducer
