import { BILL_API } from "api/urls"
import { useEffect } from "react"
import { useAuth } from "store/auth-context"
import { resetActions } from "store/slices/reset-slice"
import { successNotfication, errorNotification } from "helpers/notification"
import { useDispatch } from "react-redux"



export const useDeleteBill = (remove, id, reason, toggle) => {
  const auth = useAuth()
  const dispatch = useDispatch()
  useEffect(() => {
    if (remove && reason) {
      const deleteBill = async () => {
        const response = await fetch(BILL_API + id + '/', {
          method: 'DELETE',
          headers: {
            'Authorization': 'Token ' + auth.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            note: reason,
          })
        });

        if(!response.ok) {
          if (response.status === 401) auth.logout()
          errorNotification()
          throw Error('status error: status=' + response.status)
        }

        successNotfication()
        dispatch(resetActions.resetState())
        toggle()
      }

      deleteBill()
    }
  }, [remove, id, reason, auth, dispatch, toggle])
}
