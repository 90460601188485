// import { Button } from "@material-ui/core";
import React, { useCallback } from "react";

import {
  Input,
  Col,
  Label,
  FormGroup,
  Button,
  // FormFeedback,
} from "reactstrap";

import { decimalInTwoStep } from "shared/utility";


const DebtForm = props => {
  // pass phrase

  const { services, onChange } = props;

  const getOptions = useCallback(() => {
    if (!services) return null;
    return services.map((service, i) => {
      if (props.service && props.service === service.id) {
        return <option value={service.id} selected key={i}>{service.name}</option>
      }

      return <option value={service.id} key={i}>{service.name}</option>
    });

  }, [services, props.service]);

  const onInputChange = useCallback((column, value) => {

    const data = {
      service: (column !== 'service') ? props.service : value,
      amount: (column !== 'amount') ? props.amount :  decimalInTwoStep(value),
    }
    onChange(props.debtID, data);
  }, [props.service, props.amount, props.debtID, onChange]);

  // const serviceLabel = `service${props.debtID}`;
  // const mainLabel = `main${props.debtID}`;
  // const finalLabel = `final${props.debtID}`;
  return (
    <>
      <Col md="4">
        <FormGroup>
          <Label>Vergi növü *</Label>
          <Input
            placeholder="Vergi növü"
            type="select"
            className="form-control form-control-sm"
            onChange={(e) => onInputChange('service', e.target.value)}
            required
          >
            <option value="" defaultValue> - - - - - - - </option>
            {getOptions()}
          </Input>
          {/* {errors[serviceLabel] && <FormFeedback>Vergi növü daxil edin!</FormFeedback>} */}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <Label>Borc *</Label>
          <Input
            placeholder="Borc"
            type="number"
            className="form-control form-control-sm"
            onChange={(e) => onInputChange('amount', e.target.value)}
            value={props.amount ?? ''}
            // {...register(mainLabel, { required: true })}
            // invalid={(errors[mainLabel])? true: false}
            required
          />
          {/* {errors[mainLabel] && <FormFeedback>Xalis borc daxil edin!</FormFeedback>} */}
        </FormGroup>
      </Col>
      <Col md="1" className="align-self-center">
        <Button onClick={props.onDelete} className="btn btn-sm" color="danger"><i className="fa fa-window-close"></i></Button>
      </Col>

    </>
  );
}



export default DebtForm;
