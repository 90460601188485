import { createSlice } from "@reduxjs/toolkit";


const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    paymentTitle: null,
    paymentTotal: 0,
    notificationTitle: null,
    notificationTotal: 0,
  },
  reducers: {
    setState(state, action){
      const data = action.payload
      state.paymentTitle = data.payment.title
      state.paymentTotal = data.payment.value
      state.notificationTitle = data.notification.title
      state.notificationTotal = data.notification.value
    },
    setPayment(state, action){
      state.paymentTotal = action.payload
    },
    setNotification(state, action){
      state.notificationTotal = action.payload
    }
  }
})


export const dashboardActions = dashboardSlice.actions
const dashboardReducer = dashboardSlice.reducer
export default dashboardReducer
