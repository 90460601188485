import React, { useState, useCallback } from "react"
import { useRegion } from "hooks/address"
import {
  Container,
  Card,
  NavLink,
  CardHeader,
  FormGroup,
  InputGroup,
  Input,
  Table,
  CardBody,
  Row,
  Col,
  Button
} from 'reactstrap'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useParams } from "react-router-dom"
import RegionAddModal from "components/region/RegionAddModal"


const Regions = (props) => {
  const regions = useRegion()
  const { history } = props
  const { manager } = useParams()

  const [search, setSearch] = useState(null)
  const [modal, showModal] = useState(false)

  let filteredRegions = [...regions]

  if (search) {
    filteredRegions = filteredRegions.filter(region => {
      return region.name
        .toLowerCase()
        .includes(search.toLowerCase())
    })
  }

  const handleRowClick = useCallback((id) => {
    history.replace(`/${manager}/regions/${id}`);
  }, [history, manager])

  const toggleModal = () => showModal(!modal)

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" href="#" className="p-0" disabled>
          Rayon - Küçə
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <Row>
            <Col md='4' xl='6'>
              <h3 className="mb-0 custom-card-header">Rayon - Küçə</h3>
            </Col>
            <Col md='4' xl='4'>
              <FormGroup className=''>
                <InputGroup className='input-group-merge input-group-sm'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      <i className='fa fa-search'></i>
                    </span>
                  </div>
                  <Input
                    type='text'
                    className=''
                    onKeyUp={(e) => setSearch(e.target.value)}
                    placeholder='Axtar'
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md='4' xl='2'>
              <Button
                color="info"
                className="btn-sm float-md-right"
                onClick={toggleModal}
              >
                Yenisini yarat
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th>
                  Adı
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRegions && filteredRegions.length ?
                filteredRegions.map((region, i) =>
                  <tr key={i} onClick={() => handleRowClick(region.id)}>
                    <td>{region.name}</td>
                  </tr>
                ) :
                <tr><td colSpan="6"><h5 className='text-center'>Nəticə tapılmadı</h5></td></tr>
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {modal &&
        <RegionAddModal
          isOpen={modal}
          toggle={toggleModal}
        />
      }
    </Container>
  )
}



export default Regions
