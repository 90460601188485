import React from "react";

import { Route, Redirect, Switch } from "react-router-dom";
import Admin from 'containers/Admin';
// import Logout from "views/Logout";
import FinanceRouters from 'containers/Finance';
import { useCompany } from "store/company-context";
import { useCompanyData } from "hooks/company";
import { useInitSocket } from "hooks/socket";


const Main = () => {
  const {managements} = useCompany()

  useInitSocket()

  useCompanyData()

  let liftRoute, liftRedirect, baseRoute, baseRedirect;

  if(managements && managements.length > 0){
    if (managements.length === 1) {
      switch (managements[0].code){
        case "901":
          liftRedirect = <Redirect from='/' to="/baklift-1" />;
          break;
        case "902":
          liftRedirect = <Redirect from='/' to="/baklift-2" />;
          break;

        case "903":
          liftRedirect = <Redirect from='/' to="/baklift-3" />;
          break;
        case "904":
          liftRedirect = <Redirect from='/' to="/bma" />;
          break;
        case "905":
          liftRedirect = <Redirect from='/' to="/temp" />;
          break;
        case "100":
          liftRedirect = <Redirect from='/' to="/sumaks" />;
          break;
        default:
          return null;
      }
    };

    liftRoute = <Route path="/:manager" render={(props) => {
      if (['baklift-1', 'baklift-2', 'baklift-3', 'bma', 'temp', 'sumaks'].includes(props.match.params.manager)) {
        return <FinanceRouters {...props} />
      }
      return <Redirect to="/" />;
    }} />;

    if(managements.length > 1){
      baseRoute = <Route path="/" render={(props) => <Admin {...props} />} />;
      baseRedirect = <Redirect to="/" />;
    }
  }


  return (
    <Switch>
      {/* <Route path="/auth/logout" render={(props) => <Logout {...props} />} /> */}
      {liftRoute}
      {liftRedirect}
      {baseRoute}
      {baseRedirect}
    </Switch>
  );
}

export default Main;
