import React, { useEffect, useState, useCallback } from "react";
import { INSPECTORS } from "api/urls";
import { useLocation } from "react-router-dom";
import DatePicker from 'components/DatePicker';
import { shortName } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';
import { Pagination } from '@material-ui/lab'
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  NavLink,
} from "reactstrap";


const useInspectorList = ({ page, fullName, phone, start, end, id, excel, changeExcel }) => {
  const [count, setCount] = useState(null);
  const [pages, setPages] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    let url = INSPECTORS;
    localStorage.setItem('start', start);
    localStorage.setItem('end', end);

    if (id) {
      url += `&id=${id}`;
    }
    if (fullName) {
      url += `&full_name=${fullName}`;
    }
    if (phone) {
      url += `&phone=${phone}`;
    }
    if (start) {
      url += `&start=${start}`;
    }
    if (end) {
      url += `&end=${end}`;
    }
    if (page) {
      url += `&page=${page}`;
    }
    if (excel) {
      url += '&export=true'
    }

    const getInspectorItems = async () => {
      let headers
      if (!excel) {
        headers = {
          'Authorization': 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        }
      } else {
        headers = {
          'Authorization': 'Token ' + localStorage.getItem('token'),
        }
      }

      const response = await fetch(url, {
        method: "GET",
        headers: headers
      })

      if (!excel) {
        const data = await response.json()
        setCount(data.count)
        setPages(data.pages)
        setResults(data.results)
      } else {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Hesabat_inspector_${new Date().toJSON().slice(0, 10)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        changeExcel(false)
      }
    }

    const timeout = setTimeout(() => {
      getInspectorItems()
    }, 500)

    return () => clearTimeout(timeout)

  }, [page, fullName, phone, start, end, id, excel, changeExcel])

  return {
    count,
    pages,
    results
  }
}



const Inspector = (props) => {

  const location = useLocation();
  const [page, setPage] = useState(1);
  const [fullName, setFullName] = useState(null);
  const [phone, setPhone] = useState(null);
  // Load dates from local storage or set defaults
  const initialStart = localStorage.getItem('start') || '';
  const initialEnd = localStorage.getItem('end') || '';
  const [start, setStart] = useState(initialStart);
  const [end, setEnd] = useState(initialEnd);
  const [id, setId] = useState(null);
  const [excel, setExcel] = useState(false)


  const changeExcel = useCallback((status) => {
    setExcel(status)
  }, [])

  const { count, pages, results } = useInspectorList({ page, fullName, phone, start, end, id, excel, changeExcel });

  const { history } = props;

  const handleRowClick = useCallback((id) => {
    let url = (location.pathname.slice(-1) === '/') ? `${location.pathname}${id}` : `${location.pathname}/${id}`;
    history.replace(url);
  }, [history, location.pathname]);


  const getRows = useCallback((results = null) => {
    if (results) {
      return results.map((row, key) => {
        return (
          <tr key={key} onClick={() => { handleRowClick(row.id) }}>
            <td className="text-truncate">
              <Media className="align-items-center">
                <Media className="d-lg-block pr-3">
                  {(row.photo) ? <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={row.photo}
                    />
                  </span> :
                    <span className="profile-rounded-circle profile-rounded-circle-sm">
                      {shortName(row.full_name)}
                    </span>
                  }
                </Media>
                <Media className="d-lg-block">
                  <span className="mb-0">
                    {row.full_name}
                  </span>
                </Media>
              </Media>
            </td>
            <td className="text-truncate">{row.id}</td>
            <td className="text-truncate">{row.phone}</td>
            <td className="text-truncate">{row.payment}</td>
            <td className="text-truncate">{row.notification}</td>
            <td className="text-truncate">{row.debt && row.debt}</td>
          </tr>
        );
      });
    }
    return null;
  }, [handleRowClick]);

  const startChange = useCallback((value) => {
    if (value instanceof moment) {
      setStart(value.format('YYYY-MM-DD'));
    } else if (!value) {
      setStart('');
    }
  }, []);

  const endChange = useCallback((value) => {
    if (value instanceof moment) {
      setEnd(value.format('YYYY-MM-DD'));
    } else if (!value) {
      setEnd('');
    }
  }, []);


  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          Müfəttişlər
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">

          <Row>
            <Col>
              <h3 className="mb-0 custom-card-header">Müfəttişlər</h3>
            </Col>
            <Col>
              <Button className="btn float-right"
                color="success"
                onClick={(e) => setExcel(true)}
                disabled={excel}
              >
                Excel <i className="ml-1 fas fa-file-download"></i>
              </Button>
            </Col>
          </Row>

        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Row>
            <Col md="7" >
              <DatePicker start={start} end={end} onStartChange={startChange} onEndChange={endChange} />
            </Col>
          </Row>

          <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{count ?? 0} Nəticə</span>
          <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">AD, SOYAD</th>
                <th scope="col">ID</th>
                <th scope="col">ƏLAQƏ NÖMRƏSİ</th>
                <th scope="col">ÖDƏNİŞ</th>
                <th scope="col">BİLDİRİŞ</th>
                <th scope="col">BORC</th>
              </tr>
              <tr>
                <th scope="col">
                  <Input
                    placeholder="Ad, Soyad"
                    type="text"
                    value={fullName ?? ''}
                    onChange={(e) => {
                      setFullName(e.target.value !== '' ? e.target.value : null)
                      setPage(1)
                    }}
                    className="form-control-sm"
                  />
                </th>
                <th scope="col">
                  <Input
                    placeholder="ID"
                    type="text"
                    value={id ?? ''}
                    onChange={(e) => {
                      setId(e.target.value !== '' ? e.target.value : null)
                      setPage(1)
                    }}
                    className="form-control-sm"
                  />
                </th>
                <th scope="col">
                  <Input
                    placeholder="Əlaqə nömrəsi"
                    type="text"
                    className="form-control-sm"
                    value={phone ?? ''}
                    onChange={(e) => {
                      setPhone(e.target.value !== '' ? e.target.value : null)
                      setPage(1)
                    }}
                  />
                </th>
                <th scope="col">
                  <Input placeholder="Ödəniş" type="text" className="form-control-sm" disabled />
                </th>
                <th scope="col">
                  <Input placeholder="Bildiriş" type="text" className="form-control-sm" disabled />
                </th>
                <th scope="col">
                  <Input placeholder="Borc" type="text" className="form-control-sm" disabled />
                </th>
              </tr>
            </thead>
            <tbody>
              {getRows(results)}
            </tbody>
          </Table>
        </CardBody>
        {(results) ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                count={pages}
                page={page}
                onChange={(_, page) => {
                  setPage(page)
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
                className="pagination justify-content-end mb-0"
                color="primary"
              />
            </nav>
          </CardFooter> : null}
      </Card>
    </Container>
  );
}



export default Inspector;
