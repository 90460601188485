import React, { useCallback, Fragment } from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

import moment from 'moment';


import "moment/locale/az";

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  Button
} from "reactstrap";

moment.locale('az');
const Datepicker = (props) => {

  const { start, end, onStartChange, onEndChange } = props;

  const startDate = (start) ? moment(Date.parse(start)).locale('az') : null;

  const endDate = (end) ? moment(Date.parse(end)).locale('az') : null;

  const disableFuture = useCallback(current => {
    const today = moment().locale('az');
    return current.isBefore(today);
  }, [])

  const checkStartDate = useCallback(current => {
    if (endDate) {
      if (endDate.format('YYYY-MM-DD') < current.format('YYYY-MM-DD')) return false;
    }

    return disableFuture(current);
  }, [disableFuture, endDate]);

  const checkEndDate = useCallback((current) => {
    if (startDate) {
      if (current.format('YYYY-MM-DD') < startDate.format('YYYY-MM-DD')) return false;
    }

    return disableFuture(current);
  }, [disableFuture, startDate]);

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Başlama tarixi",
                  value: start ?? '',
                }}
                isValidDate={checkStartDate}
                timeFormat={false}
                dateFormat='YYYY-MM-DD'
                value={start ?? ''}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
                  ) {
                    if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD') <
                    currentDate.format('YYYY-MM-DD') &&
                    endDate.format('YYYY-MM-DD') >
                    currentDate.format('YYYY-MM-DD')
                  ) {
                    classes += " middle-date";
                  } else if (
                    startDate &&
                    endDate &&
                    endDate.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
                  ) {
                    classes += " end-date";
                  }


                  let disabled = false;

                  if (endDate && endDate.format('YYYY-MM-DD') < currentDate.format('YYYY-MM-DD')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  } else if (currentDate.format('YYYY-MM-DD') > moment(new Date()).locale('az').format('YYYY-MM-DD')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  }
                  return (
                    <td {...props} className={classes} disabled={disabled}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={onStartChange}
              />
              {start && <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Button
                    onClick={(e) => onStartChange(null)}
                    className='btn-xsm rounded-circle p-auto m-0'
                    color="secondary"
                  >
                    <i className="fa fa-times text-danger" />
                  </Button>
                </InputGroupText>
              </InputGroupAddon>}
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Bitmə tarixi",
                  value: end ?? '',
                }}
                isValidDate={checkEndDate}
                timeFormat={false}
                locale="az"
                dateFormat='YYYY-MM-DD'
                value={end ?? ''}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
                  ) {
                    if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD') <
                    currentDate.format('YYYY-MM-DD') &&
                    endDate.format('YYYY-MM-DD') >
                    currentDate.format('YYYY-MM-DD')
                  ) {
                    classes += " middle-date";
                  } else if (
                    startDate &&
                    endDate &&
                    endDate.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
                  ) {
                    classes += " end-date";
                  }

                  let disabled = false;

                  if (currentDate.format('YYYY-MM-DD') > moment(new Date()).locale('az').format('YYYY-MM-DD')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  }
                  else if (startDate && startDate.format('YYYY-MM-DD') > currentDate.format('YYYY-MM-DD')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  }


                  return (
                    <td {...props} className={classes} disabled={disabled}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={onEndChange}
              />
              {end && <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Button
                    onClick={(e) => onEndChange(null)}
                    className='btn-xsm rounded-circle btn-secondary'
                    color="secondary"
                  >
                    <i className="fa fa-times text-danger" />
                  </Button>
                </InputGroupText>
              </InputGroupAddon>}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Datepicker;
