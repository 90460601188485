import React, { useCallback, useState } from "react"

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  FormGroup,
  Form,
  Label,
  Input
} from "reactstrap"
import { useCompany } from "store/company-context"
import { checkPermission, EDIT, DELETE } from "helpers/permission"
import { useRegion, useUpdateHouse, useDeleteHouse } from "hooks/address"
import { useParams } from "react-router-dom"


const HouseModal = ({ house, isOpen, toggle, resetState }) => {

  const regions = useRegion()
  const {id} = useParams()
  const [name, setName] = useState(house?.name ?? undefined)
  const [region, setRegion] = useState(id ?? undefined)
  const [save, setSave] = useState(false)
  const [remove, setRemove] = useState(false)
  const {permissions} = useCompany()

  useUpdateHouse(save, house?.id, region, name, resetState)
  useDeleteHouse(remove, house?.id, resetState)

  const getOptions = useCallback((list) => {
    return list.map((item, i) => {
      return (
        <option
          value={item.id}
          key={i}
        >
          {item.name}
        </option>
      )
    });
  }, [])

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault()
    if (e.target.checkValidity()) setSave(true)
  }, [])

  const edit = checkPermission(permissions, EDIT)
  const deletePerm = checkPermission(permissions, DELETE)

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={toggle}>Ev (bina): {house.name}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleOnSubmit} id='region-modal-form'>
          <FormGroup>
            <Label>Rayon / küçə *</Label>
            <Input
              type="select"
              onChange={e => setRegion(e.target.value)}
              value={region}
              className="custom-form-control-sm"
              required
              disabled={!edit}
            >
              {getOptions(regions)}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Adı *</Label>
            <Input
              placeholder="Adı"
              type="text"
              className="form-control form-control-sm"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
              disabled={!edit}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="info"
          disabled={save || !edit}
          type='submit'
          form="region-modal-form"
          className="btn-sm"
        >
          Yadda saxla
        </Button>
        {' '}
        <Button
          color="danger"
          disabled={remove || !deletePerm}
          type='button'
          className="btn-sm"
          onClick={e => {
            if(deletePerm) setRemove(true)
          }}
        >
          Sil
        </Button>
        {' '}
        <Button
          className="ml-auto btn-sm"
          color="link"
          onClick={toggle}
        >
          Ləğv et
        </Button>
      </ModalFooter>
    </Modal>
  )
}


export default HouseModal
