import React from "react";

import Odometer from 'react-odometerjs';
import { useDashboardData } from "hooks/dashboard";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { useSelector } from "react-redux";


const Meter = () => {

  const {
    notificationTitle,
    notificationTotal,
    paymentTitle,
    paymentTotal
  } = useSelector(state => state.dashboard)

  useDashboardData()


  return (
    <Row>
      <Col className="mb-5 mb-xl-0" xl="6">
        <Card className="bg-notification shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <Col>
                <h3 className="text-white ls-1 mb-1">
                  {notificationTitle}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <h1 className="w-100 text-center counter-text-style">
              <Odometer value={notificationTotal} format="(.ddd),dd" theme='plaza' /> <span className="text-white ml-2">₼</span>
            </h1>
          </CardBody>
        </Card>
      </Col>
      <Col className="mb-5 mb-xl-0" xl="6">
        <Card className="bg-payment shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <Col>
                <h3 className="text-white ls-1 mb-1">
                  {paymentTitle}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <h1 className="w-100 text-center counter-text-style">
              <Odometer value={paymentTotal} format="(.ddd),dd" theme='plaza' /> <span className="text-white ml-2">₼</span>
            </h1>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}


export default Meter;
