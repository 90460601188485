import React, { useCallback, useState } from "react"
import { useHouse } from "hooks/address"
import HouseModal from "./HouseModal"

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Input,
  Button
} from 'reactstrap'
import HouseAddModal from "./HouseAddModal"


const House = ({ id }) => {
  const [search, setSearch] = useState(null)
  const [selectedHouse, setSelectedHouse] = useState(null)
  const [addModal, setAddModal] = useState(false)
  const houses = useHouse(id)

  let filteredHouses = [...houses]

  if (search) {
    filteredHouses = filteredHouses.filter(house => {
      return house.name
        .toLowerCase()
        .includes(search.toLowerCase())
    })
  }

  const toggle = useCallback(() => {
    setSelectedHouse(null)
  }, [])

  const toggleAddModal = useCallback(() => setAddModal(false), [])

  const resetState = useCallback(() => {
    setSearch(null)
    setSelectedHouse(null)
    setAddModal(false)
  }, [])

  return (
    <Card className="shadow mb-5">
      <CardHeader className="border-0">
        <Row>
          <Col md='5' xl='7'>
            <h3 className="mb-0 custom-card-header">Evlər (Binalar)</h3>
          </Col>
          <Col md='5' xl='3'>
            <FormGroup>
              <InputGroup className='input-group-merge input-group-sm'>
                <div className='input-group-prepend'>
                  <span className='input-group-text'>
                    <i className='fa fa-search'></i>
                  </span>
                </div>
                <Input
                  type='text'
                  className=''
                  onKeyUp={(e) => setSearch(e.target.value)}
                  placeholder='Axtar'
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md='2' xl='2'>
            <Button
              color="info"
              className="btn-sm float-md-right"
              onClick={e => setAddModal(true)}
            >
              Yeni ev / bina
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <hr className="my-1" />
      <CardBody>
        <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
          <thead className="thead-light">
            <tr>
              <th>
                Adı
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredHouses && filteredHouses.length ?
              filteredHouses.map((house, i) =>
              <tr
                key={i}
                onClick={e => setSelectedHouse(house)}
              >
                <td>{house.name}</td>
              </tr>) :
              <tr><td colSpan="6"><h5 className='text-center'>Nəticə tapılmadı</h5></td></tr>
            }
          </tbody>
        </Table>
      </CardBody>
      {selectedHouse && <HouseModal
        isOpen={!!selectedHouse}
        toggle={toggle}
        house={selectedHouse}
        resetState={resetState}
      />}
      {addModal && <HouseAddModal
        isOpen={addModal}
        toggle={toggleAddModal}
        resetState={resetState}
      />}
    </Card>
  )
}



export default House
