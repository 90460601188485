export const BASE_URL = "https://lift.sumaks.az/api/v1/";

export const LOGIN = `${BASE_URL}auth/login/`;
export const LOGOUT = `${BASE_URL}auth/logout/`;

export const COMPANY = `${BASE_URL}company/`;

export const MANAGEMENTS = `${BASE_URL}managements/`;

export const PERMISSIONS = `${BASE_URL}auth/permissions/`;

export const PAYERS = `${BASE_URL}payers/?limit=100`;

export const PAYER_DETAIL = `${BASE_URL}payers/`;

export const DEBT = `${BASE_URL}debt/`;

export const INSPECTORS = `${BASE_URL}inspectors/?limit=100`;

export const INSPECTOR_DETAIL = `${BASE_URL}inspectors/detail/`;

export const WEB_SOCKET = 'https://lift.sumaks.az';

export const INDEX_API_MONTHLY = `${BASE_URL}statistic/monthly/`;

export const INDEX_API_TOTAL = `${BASE_URL}statistic/total/`;

export const INDEX_API_SERVICE = `${BASE_URL}statistic/service/`;

export const INDEX_API_WEEKLY = `${BASE_URL}statistic/weekly/`;

export const NAVBAR_SEARCH = `${BASE_URL}common/search`;

export const ACTIVITY_LOG_API = `${BASE_URL}activity/`;

export const ADD_PAYER_POP_API = `${BASE_URL}payers/`;

export const SERVICES_API = `${BASE_URL}services/`;

export const REGIONS_API = `${BASE_URL}regions/`;

export const HOUSES_API = `${BASE_URL}houses/`;

export const BILL_API = `${BASE_URL}bill/`;

export const BANK_BILL_API = `${BASE_URL}bankbills/?limit=100`;

export const DELETE_BILL_API = `${BASE_URL}deletebills/?limit=100`;

