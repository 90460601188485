import React, { useEffect, useState, useCallback } from "react";
import { BANK_BILL_API } from "api/urls";
import { useLocation } from "react-router-dom";
import DatePicker from 'components/DatePicker';
// import { shortName } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';
import { Pagination } from '@material-ui/lab'
import {
    Card,
    CardHeader,
    CardFooter,
    // Media,
    Table,
    Container,
    Button,
    Input,
    Row,
    Col,
    CardBody,
    NavLink,
} from "reactstrap";


const useBankBillList = ({ page, id, start, end, memberId, fullName, adress, flat, paymentType, amount, createdDate, excel, changeExcel }) => {
    const [count, setCount] = useState(null);
    const [pages, setPages] = useState(null);
    const [results, setResults] = useState([]);

    useEffect(() => {
        let url = BANK_BILL_API;

        if (id) {
            url += `&id=${id}`;
        }
        if (memberId) {
            url += `&member_id=${memberId}`;
        }
        if (fullName) {
            url += `&full_name=${fullName}`;
        }
        if (adress) {
            url += `&address=${adress}`;
        }
        if (flat) {
            url += `&flat=${flat}`;
        }
        // if (serviceName) {
        //     url += `&service_name=${serviceName}`;
        // }
        if (paymentType) {
            url += `&payment_type=${paymentType}`;
        }
        if (amount) {
            url += `&amount=${amount}`;
        }
        if (createdDate) {
            url += `&created_at=${createdDate}`;

        }
        if (start) {
            url += `&start=${start}`;
        }
        if (end) {
            url += `&end=${end}`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (excel) {
            url += '&export=true'
        }


        const getBankItems = async () => {
            let headers
            if (!excel) {
                headers = {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                }
            } else {
                headers = {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                }
            }

            const response = await fetch(url, {
                method: "GET",
                headers: headers
            })

            if (!excel) {
                const data = await response.json()
                setCount(data.count)
                setPages(data.pages)
                setResults(data.results)
            } else {
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Hesabat_bank_${new Date().toJSON().slice(0, 10)}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                changeExcel(false)
            }
        }

        const timeout = setTimeout(() => {
            getBankItems()
        }, 500)

        return () => clearTimeout(timeout)

    }, [page, id, start, end, memberId, fullName, adress, flat, paymentType, amount, createdDate, excel, changeExcel])

    return {
        count,
        pages,
        results
    }
}



const BankBill = (props) => {

    const location = useLocation();
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [memberId, setMemberId] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [adress, setAdress] = useState(null);
    const [flat, setFlat] = useState(null);
    // const [serviceName, setServiceName] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [amount, setAmount] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [excel, setExcel] = useState(false)

    const changeExcel = useCallback((status) => {
        setExcel(status)
    }, [])



    const { count, pages, results } = useBankBillList({ page, id, start, end, memberId, fullName, adress, flat, paymentType, amount, createdDate, excel, changeExcel });

    const { history } = props;

    const handleRowClick = useCallback((id) => {
        let url = (location.pathname.slice(-1) === '/') ? `${location.pathname}${id}` : `${location.pathname}/${id}`;
        history.replace(url);
    }, [history, location.pathname]);


    const getRows = useCallback((results = null) => {
        if (results) {
            return results.map((row, key) => {
                return (
                    <tr key={key} onClick={() => { handleRowClick(row.id) }}>
                        {/* <td className="text-truncate">
                            <Media className="align-items-center">
                                <Media className="d-lg-block pr-3">
                                    {(row.photo) ? <span className="avatar avatar-sm rounded-circle">
                                        <img
                                            alt="..."
                                            src={row.photo}
                                        />
                                    </span> :
                                        <span className="profile-rounded-circle profile-rounded-circle-sm">
                                            {shortName(row.full_name)}
                                        </span>
                                    }
                                </Media>
                                <Media className="d-lg-block">
                                    <span className="mb-0">
                                        {row.full_name}
                                    </span>
                                </Media>
                            </Media>
                        </td> */}
                        <td className="text-truncate">{row.id}</td>
                        <td className="text-truncate">{row.member_id}</td>
                        <td className="text-truncate">{row.full_name}</td>
                        <td className="text-truncate">{row.address}</td>
                        <td className="text-truncate">{row.flat}</td>
                        <td className="text-truncate">{row.payment_type}</td>
                        <td className="text-truncate">{row.amount}</td>
                        <td className="text-truncate">{row.created_at}</td>
                    </tr>
                );
            });
        }
        return null;
    }, [handleRowClick]);

    const startChange = useCallback((value) => {
        if (value instanceof moment) {
            setStart(value.format('YYYY-MM-DD'));
        } else if (!value) {
            setStart(null);
        }
    }, []);

    const endChange = useCallback((value) => {
        if (value instanceof moment) {
            setEnd(value.format('YYYY-MM-DD'));
        } else if (!value) {
            setEnd(null);
        }
    }, []);


    return (
        <Container className="custom-margin-top" fluid>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
                <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
                    Bank Ödənişləri
                </NavLink>
            </Breadcrumbs>
            <Card className="shadow mb-5">
                <CardHeader className="border-0">

                    <Row>
                        <Col>
                            <h3 className="mb-0 custom-card-header">Bank Ödənişləri</h3>
                        </Col>
                        <Col>
                            <Button className="btn float-right"
                                color="success"
                                onClick={(e) => setExcel(true)}
                                disabled={excel}
                            >
                                Excel <i className="ml-1 fas fa-file-download"></i>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <hr className="my-1" />
                <CardBody>
                    <Row>
                        <Col md="7" >
                            <DatePicker start={start} end={end} onStartChange={startChange} onEndChange={endChange} />
                        </Col>
                    </Row>
                    {/* <ul className="inspector-tabset mt-3">
                        <li className="inspector-tabset-tab">
                            <span className="tab">
                                YIĞIM CƏMİ : {11}
                            </span>
                        </li>
                    </ul> */}

                    <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{count ?? 0} Nəticə</span>
                    <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">REFERANS №</th>
                                <th scope="col">ABONENT KODU</th>
                                <th scope="col">AD, SOYAD, ATA ADI</th>
                                <th scope="col">ÜNVAN</th>
                                <th scope="col">MƏNZİL</th>
                                <th scope="col">VERGİ NÖVÜ</th>
                                <th scope="col">MƏBLƏĞ</th>
                                {/* <th scope="col">ÖDƏNİŞİN TƏYİNATI</th> */}
                                <th scope="col">TARİX</th>
                            </tr>
                            <tr>
                                <th scope="col">
                                    <Input
                                        placeholder="ID"
                                        type="text"
                                        value={id ?? ''}
                                        onChange={(e) => {
                                            setId(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                        className="form-control-sm"
                                        disabled
                                    />
                                </th>
                                <th scope="col">
                                    <Input
                                        placeholder="Abonent kodu"
                                        type="text"
                                        value={memberId ?? ''}
                                        onChange={(e) => {
                                            setMemberId(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                        className="form-control-sm"
                                    />
                                </th>
                                <th scope="col">
                                    <Input
                                        placeholder="Ad, Soyad, Ata adı"
                                        type="text"
                                        value={fullName ?? ''}
                                        onChange={(e) => {
                                            setFullName(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                        className="form-control-sm"
                                    />
                                </th>

                                <th scope="col">
                                    <Input
                                        placeholder="Ünvan"
                                        type="text"
                                        className="form-control-sm"
                                        disabled
                                        value={adress ?? ''}
                                        onChange={(e) => {
                                            setAdress(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                    />
                                </th>
                                <th scope="col">
                                    <Input
                                        placeholder="Mənzil"
                                        type="text"
                                        className="form-control-sm"
                                        disabled
                                        value={flat ?? ''}
                                        onChange={(e) => {
                                            setFlat(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                    />
                                </th>
                                <th scope="col">
                                    <Input
                                        placeholder="Vergi Növü"
                                        type="text"
                                        className="form-control-sm"
                                        disabled
                                        value={paymentType ?? ''}
                                        onChange={(e) => {
                                            setPaymentType(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                    />
                                </th>
                                <th scope="col">
                                    <Input
                                        placeholder="Məbləğ"
                                        type="text"
                                        className="form-control-sm"
                                        disabled
                                        value={amount ?? ''}
                                        onChange={(e) => {
                                            setAmount(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                    />
                                </th>
                                <th scope="col">
                                    <Input
                                        placeholder="Tarix"
                                        type="text"
                                        className="form-control-sm "
                                        disabled
                                        value={createdDate ?? ''}
                                        onChange={(e) => {
                                            setCreatedDate(e.target.value !== '' ? e.target.value : null)
                                            setPage(1)
                                        }}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {getRows(results)}
                        </tbody>
                    </Table>
                </CardBody>
                {(results) ?
                    <CardFooter className="py-4">
                        <nav aria-label="...">
                            <Pagination
                                count={pages}
                                page={page}
                                onChange={(_, page) => {
                                    setPage(page)
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    })
                                }}
                                className="pagination justify-content-end mb-0"
                                color="primary"
                            />
                        </nav>
                    </CardFooter> : null}
            </Card>
        </Container>
    );
}


export default BankBill;
