import { useEffect, useState } from "react"
import { useAuth } from "store/auth-context"
import { INDEX_API_MONTHLY, INDEX_API_WEEKLY } from 'api/urls'


export const useBarData = ({activeNav, inspector}) => {
  const auth = useAuth()
  const [state, setState] = useState({
    data: [],
    labels: []
  })
  useEffect(() => {
    const fetchData = async () => {
      const url = new URL((activeNav === 1) ? INDEX_API_MONTHLY : INDEX_API_WEEKLY)
      if (inspector) url.searchParams.set('inspector_id', inspector)

      const response = await fetch(url, {
        method: "GET",
        headers: {
          'Authorization': 'Token ' + auth.token,
          'Content-Type': 'application/json',
        }
      })

      if(!response.ok) {
        if (response.status === 401) auth.logout()
        throw Error('status error: status=' + response.status)
      }

      const data = await response.json()
      setState({
        data: data.data,
        labels: data.chartLabel,
      })
    }

    fetchData()

  }, [auth, activeNav, inspector])


  return state
}
