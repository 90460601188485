import { createSlice } from "@reduxjs/toolkit";


const resetSlice = createSlice({
  name: 'reset',
  initialState: {
    reset: false,
  },
  reducers: {
    resetState(state) {
      state.reset = !state.reset
    },
  }
})


export const resetActions = resetSlice.actions
const resetReducer = resetSlice.reducer
export default resetReducer
