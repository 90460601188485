import React, { useEffect, useState, Fragment } from "react"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { shortName } from 'helpers/utils'
import { PAYER_DETAIL } from "api/urls"
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  NavLink,
  Button,
  Input,
  Col,
  Form,
  // FormFeedback
} from "reactstrap"

import { useCompany } from "store/company-context"
import { useAuth } from "store/auth-context"

import Address from 'components/Payer/Address'
import DeletePayerModal from "./DeletePayerModal"
import { updatePayerData } from "store/actions/payerDetail"
import { successNotfication, errorNotification } from "helpers/notification"
import { checkPermission, EDIT, DELETE } from "helpers/permission"
import PhoneInput from 'react-phone-number-input/input'
import { useHistory, useLocation } from "react-router-dom"



const PayerForm = props => {
  const location = useLocation()
  const history = useHistory()

  const { permissions } = useCompany()
  const auth = useAuth()

  const [fullName, setFullName] = useState(null)
  const [memberId, setMemberId] = useState(null)
  const [address, setAdress] = useState(null)
  const [amount, setAmount] = useState(null)
  const [{region, house}, setRegionHouse] = useState({
    region: null,
    house: null,
  })
  const [flat, setFlat] = useState(null)
  const [phone, setPhone] = useState(null)
  const [livedPeople, setLivedPeople] = useState(null)
  const [note, setNote] = useState(null)

  const {id} = props
  const [modal, setModal] = useState(false)
  const [_, setData] = useState(null)

  useEffect(() => {
    const payerDetail = async () => {
      const URL = PAYER_DETAIL + `${id}/`;
      const response = await fetch(URL, {
        method: "GET",
        headers: {
          'Authorization': 'Token ' + auth.token,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        if(response.status === 401) auth.logout()
        throw Error('An error occured')
      }

      const data = await response.json();
      setFullName(data.full_name);
      setMemberId(data.member_id);
      setAdress(data.address);
      setAmount(data.amount);
      setRegionHouse({
        region: data.region,
        house: data.house,
      });
      setFlat(data.flat);
      setPhone(data.phone);
      setNote(data.note);
      setLivedPeople(data.lived_people);
    }

    payerDetail();
  }, [id, auth]);

  useEffect(() => {
    if (_) {
      updatePayerData(auth, id, _)
        .then(status => {
          successNotfication()
          setData(null);
        }).catch(err => {
          errorNotification()
          setData(null);
        })
    }
  }, [_, id, auth]);

  const readOnly = !checkPermission(permissions, EDIT);


  return (
    <Fragment>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" onClick={() => history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1))} className="p-0 cursor-pointer">
          Abonentlər
        </NavLink>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          {fullName}
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row>
            <Col md='6'>
              <h2 className="mb-0 d-flex" >
                <span className="profile-rounded-circle">
                  {shortName(fullName)}
                </span>
                <span className="profile-circle-name">{fullName}</span>
              </h2>
            </Col>
            <Col md='6'>
              <h2 className="profile-circle-name float-right">Abonent kodu: {memberId}</h2>
            </Col>
          </Row>
        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Form onSubmit={ e => {
            e.preventDefault();
            if (e.target.checkValidity() && !readOnly) {
              setData({
                full_name: fullName,
                lived_people: livedPeople,
                address,
                region,
                house,
                flat,
                phone,
                note
              })
            } else{
              e.target.reportValidity();
            }
          }}>
            <Row>
              <Col md="4">
                <Row>
                  <Col md="4" className="mb-2">Ad / Soyad *:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Ad / Soyad"
                      required
                      type="text"
                      className="custom-form-control-sm"
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName ?? ''}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col md="4" className="mb-2">Ailə tərkibi:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Ailə tərkibi"
                      type="number"
                      className="custom-form-control-sm"
                      value={(livedPeople) ? livedPeople : ''}
                      readOnly={readOnly}
                      onChange={(e) => setLivedPeople(e.target.value)}
                    />
                  </Col>
                  <Col md="4" className="mb-2">Ünvan *:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Ünvan"
                      type="textarea" row={2}
                      className="custom-form-control-sm"
                      required
                      value={(address) ? address : ''}
                      onChange={(e) => setAdress(e.target.value)}
                      readOnly={readOnly}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Row>
                  <Col md="4" className="mb-2">Borc:</Col>
                  <Col md="8" className="mb-3">
                    <Input placeholder="Borc" type="text" className="custom-form-control-sm" value={`${(amount) ? amount : 0} ₼`} readOnly />
                  </Col>
                  <Col md="4" className="mb-2">Əlaqə nömrəsi:</Col>
                  <Col md="8" className="mb-3">
                    <PhoneInput
                      placeholder="Əlaqə nömrəsi"
                      country="AZ"
                      defaultCountry="AZ"
                      className="form-control form-control-sm"
                      international
                      withCountryCallingCode
                      value={phone ?? ''}
                      onChange={(p) => setPhone(p)}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col md="4" className="mb-2">Qeyd:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Qeyd"
                      type="textarea" row={2}
                      className="custom-form-control-sm"
                      value={(note) ? note : ''}
                      onChange={(e) => setNote(e.target.value)}
                      readOnly={readOnly}
                    />
                  </Col>
                </Row>

              </Col>
              <Col md="4">
                <Address
                  region={region}
                  house={house}
                  setRegionHouse={setRegionHouse}
                  method='edit'
                  disabled={readOnly}
                />
                <Row>
                  <Col md="4" className="mb-2">Mənzil:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Mənzil"
                      type="text"
                      className="custom-form-control-sm"
                      value={`${flat}`}
                      onChange={e => setFlat(e.target.value)}
                      readOnly={readOnly}
                    />
                  </Col>
                </Row>
              </Col>

            </Row>
            <Row className="pt-2">
              <Col md="6" className="mt-2">
                { checkPermission(permissions, DELETE) && <Button className="btn custom-btn-style"
                  color="danger"
                  type="button"
                  onClick={e => setModal(!modal)}
                >
                  Abonenti sil
                </Button>}
              </Col>
              <Col md="6" className="mt-2">
                <Button className="btn custom-btn-style custom-float-btn"
                  color="info"
                  type="submit"
                  disabled={!!_ || readOnly}
                >
                  Yadda saxla
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      { checkPermission(permissions, DELETE) && <DeletePayerModal
        setModal={setModal}
        modal={modal}
        id={id}
        history={history}
        location={location}
      />}
    </Fragment>
  );
}


export default PayerForm;
