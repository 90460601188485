
import React from "react";

import {
  Container,
} from "reactstrap";

const Footer = (props) => {
  return (
    <footer className={props.className ?? "bg-white custom-footer mt-auto mb-0"}>
      <Container>
        <div className="copyright text-center text-muted">
          © {new Date().getFullYear()}{" "}
          <span className="font-weight-bold ml-1">
            BAKU LIFT
          </span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
