import { useEffect, useState } from "react"
import { useAuth } from "store/auth-context"
import { PAYERS } from "api/urls";


export const usePayers = ({
  page, memberId, fullName, payerAddress, amount, flat, debtStatus, excel, setExcel
}) => {

  const auth = useAuth()
  const [state, setState] = useState({
    count: null,
    pages: null,
    results: null,
  })

  useEffect(() => {
    const fetchPayers = async () => {
      const url = new URL(PAYERS)
      if (page) {
        url.searchParams.set('page', page)
      }
      if (memberId) {
        url.searchParams.set('member_id', memberId)
      }
      if (fullName) {
        url.searchParams.set('full_name', fullName)
      }
      if (payerAddress) {
        url.searchParams.set('address', payerAddress)
      }
      if (amount) {
        url.searchParams.set('amount', amount)
      }
      if (debtStatus) {
        url.searchParams.set('debt_status', debtStatus)
      }
      if (flat) {
        url.searchParams.set('flat', flat)
      }
      if (excel) {
        url.searchParams.set('export', 'true')
      }

      const headers = {
        'Authorization': 'Token ' + auth.token,
      }
      if (!excel) {
        headers['Content-Type'] = 'application/json'
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      })

      if(!response.ok) {
        if (response.status === 401) auth.logout()
        throw Error('status error: status=' + response.status)
      }

      if (!excel) {
        const data = await response.json()
        setState({
          count: data.count,
          pages: data.pages,
          results: data.results,
        })
      } else {
        const blob = await response.blob()
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `Hesabat_${new Date().toJSON().slice(0, 10)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setExcel(false)
      }
    }
    const timeOut = setTimeout(() => fetchPayers(), [300])
    return () => clearTimeout(timeOut)
  }, [
    auth, page, memberId, fullName, payerAddress, amount, flat, debtStatus, excel, setExcel
  ])

  return state
}
