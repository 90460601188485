import React, { useCallback, useState } from "react"

import { useLocation } from "react-router-dom"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Bill from 'components/InspectorDetail/Bill'
import Header from 'components/InspectorDetail/Header'
import Statistic from "components/InspectorDetail/Statistic"
import {
  Card,
  CardHeader,
  Container,
  CardBody,
  Row,
  Col,
  NavLink,
  Nav,
  NavItem
} from "reactstrap"

import classnames from "classnames"
import { useInspectorDetail } from "hooks/inspector"


const InspectorDetail = (props) => {

  const location = useLocation();
  const {
    fullName, inspectorImage
  } = useInspectorDetail()

  const pageHeader = (location.pathname.includes('non-population')) ? "Müfəttişlər Kommersiya" : "Müfəttişlər Əhali";

  const [activeNav, setActiveNav] = useState(1);


  const toggleNavs = useCallback((e, index = 1) => {
    e.preventDefault();
    setActiveNav(index);
  }, []);

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" onClick={() => props.history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1))} className="p-0 cursor-pointer">
          {pageHeader}
        </NavLink>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          {fullName}
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow custom-card-style mb-5">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <Header
                fullName={fullName}
                inspectorImage={inspectorImage}
              />
            </Col>
            <Col className="justify-content-end">
              <Nav className="justify-content-end" pills>
                <NavItem>
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 1,
                    })}
                    href=""
                    onClick={(e) => toggleNavs(e, 1)}
                  >
                    <span className="d-none d-md-block">Qəbzlər</span>
                    <span className="d-md-none">Q</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 2,
                    })}
                    data-toggle="tab"
                    href=""
                    onClick={(e) => toggleNavs(e, 2)}
                  >
                    <span className="d-none d-md-block">Statistika</span>
                    <span className="d-md-none">S</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>

        </CardHeader>
        <hr className="my-1" />
        <CardBody>
        {(activeNav === 1) ?
          <Bill /> :
          <Statistic />}
        </CardBody>
      </Card>
    </Container>
  );
}


export default InspectorDetail;
