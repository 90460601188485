
const chars = [
  ['ə', 'Ə'],
  ['ğ', 'Ğ'],
  ['ü', 'Ü'],
  ['ş', 'Ş'],
  ['i', 'İ'],
  ['ç', 'Ç'],
];


export const strToUpper = (value) => {
  if (!value) {
    return null;
  }
  for (let i = 0; i < chars.length; i++) {
    value = value.replaceAll(chars[i][0], chars[i][1]);
  }

  return value.toUpperCase();
}


export const shortName = (value) => {
  let name = '';
  if (value) {
    const fn = value.split(" ");
    for (let i = 0; i < fn.length; i++) {
      if (i < 2) {
        name += fn[i].toUpperCase().charAt(0);
      }
    }
  }
  return name;
}


export const notificationOptions = {
  title: "Uğurlu əməliyyat!",
  message: 'Dəyişikliklər müvəffəqiyyətlə yadda saxlanıldı.',
  type: 'success',
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 2000,
    onScreen: true
  }
}




export const fileNames = (files) => {
  const names = [];
  for (let i = 0; i < files.length; i++) {
    names.push(files[i].name);
  }
  return names.join(', ');
}


export const getManagement = (managements, path) => {
  let code;
  switch (path) {
    case 'baklift-1':
      code = '901';
      break;
    case 'baklift-2':
      code = '902';
      break;
    case 'baklift-3':
      code = '903';
      break;
    case 'bma':
      code = '904';
      break;
    case 'temp':
      code = '905';
      break;
    case 'sumaks':
      code = '100';
      break;
    default:
      return null;
  }

  for (let x in managements) {
    if (managements[x].code === code){
      return managements[x];
    }
  }

  return null;
}


