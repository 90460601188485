import React, { useState, useEffect, useCallback } from "react";
import AuthLayout from "containers/Auth";
import { useAuth } from "store/auth-context";
import { LOGIN } from "api/urls";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

const Login = () => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const auth = useAuth();


  const onSubmit = useCallback((e) => {
    e.preventDefault();
    if (e.target.checkValidity()) setClicked(true);
  }, []);

  useEffect(() => {
    if (!clicked || !username || !password) return;

    const loginFetch = async () => {
      const response = await fetch(LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password,
        }),
        credentials: 'include'
      })

      if (!response.ok) {
        const errorMessage = await response.json();
        setErrorMessage(errorMessage.message?.message);
        setError(true);
        setPassword(null);
        setClicked(false);
        return;
      }

      const data = await response.json();

      setClicked(false);

      auth.login(data.token)
    }
    try {
      loginFetch();
    } catch (e) {
      console.log(e);
      setError(true);
      setPassword(null);
      setClicked(false);
    }

  }, [clicked, username, password, auth]);

  return (
    <AuthLayout>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-5">
              <h2>Daxil ol</h2>
            </div>
            {error && <div className="text-center text-danger mb-4">
              <small>{errorMessage || 'Sizin hesabınız ödəniş olunmadığı üçün bağlanılmışdır'}</small>
            </div>}
            <Form role="form mt-2" onSubmit={onSubmit}>

              <FormGroup className={error ? 'mb-3 has-danger' : 'mb-3'}>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className={error ? 'text-danger' : ''}>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="İstifadəçi adı"
                    type="text"
                    autoComplete="username"
                    autoFocus
                    onChange={(e) => {
                      setUsername(e.target.value);
                      if (error) setError(false);
                    }}
                    value={username ?? ''}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className={error ? 'has-danger' : ''}>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className={error ? 'text-danger' : ''}>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Şifrə"
                    type="password"
                    autoComplete="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError(false);
                    }}
                    value={password ?? ''}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={clicked}>
                  Daxil ol
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </AuthLayout>
  );
};

export default Login;
