import React, { useState, useEffect } from 'react';

import { deletePayer } from 'store/actions/payerDetail';

import { successNotfication, errorNotification } from 'helpers/notification';
import { useAuth } from 'store/auth-context';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from 'reactstrap';


const DeletePayerModal = props => {

  const {
    modal, setModal, id, history, location
  } = props;

  const auth = useAuth()

  const [del, setDel] = useState(false);

  useEffect(() => {
    if (del) {
      deletePayer(auth, id)
      .then((status) => {
        successNotfication()
        history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1))
      })
      .catch(err => errorNotification());
      setDel(false);
      setModal(false);
    }
  }, [del, id, history, location, setModal, auth]);

  const toggle = () => setModal(!modal);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
    >
      <ModalHeader toggle={toggle}>Abonenti silmək isdədiyinizə əminsinizmi?</ModalHeader>
      <ModalBody>
        <div className="py-2 text-center">
          <i className="fas fa-exclamation-triangle fa-3x"></i>
          <h3 className="mt-2 text-white">Abonentin silinməsi həmin abotentin borc/ödəniş məlumatlarını da təmizləyir.</h3>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-white" color="default" onClick={(e) => setDel(true)}>Bəli, silinsin</Button>{' '}
        <Button className="text-white ml-auto" color="link" onClick={toggle}>Ləğv et</Button>
      </ModalFooter>
    </Modal>
  );
}


export default DeletePayerModal;
