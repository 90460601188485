import React from "react";
import { useParams } from "react-router-dom";
import PayerForm from "components/Payer/PayerForm";
import PayerDebts from "components/Payer/PayerDebt";

import {
  Container,
} from "reactstrap";


const PayerDetail = (props) => {

  const { id } = useParams();

  return (
    <Container className="custom-margin-top" fluid>
      <PayerForm
        id={id}
      />
      <PayerDebts
        id={id}
      />
    </Container>
  );
}


export default PayerDetail;
