import React, { useCallback, useState } from 'react';

import {
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Table,
} from 'reactstrap';

import { Pagination } from '@material-ui/lab'

import DatePicker from 'components/DatePicker';
import moment from 'moment';
import { useInspectorData } from 'hooks/inspector';
import { useCompany } from 'store/company-context';
import { checkPermission, DELETE } from "helpers/permission"
import BillDeleteModal from './BillDeleteModal';

const Bill = props => {

  const [page, setPage] = useState(1)
  const [excel, setExcel] = useState(false)
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [activePage, setActivePage] = useState('payment')
  const [selectedBill, setSelectedBill] = useState(null)
  const { permissions } = useCompany()
  const deletePerm = checkPermission(permissions, DELETE)

  const {
    count,
    results,
    total_amount,
    pages
  } = useInspectorData({
    page, excel, start, end, activePage, setExcel
  })

  const getRows = useCallback((results = null) => {
    if (results) {
      return results.map((row, key) => {
        return (
          <tr key={key}>
            <td className="text-truncate" style={{ width: '9%' }}>{row.id}</td>
            <td className="text-truncate" style={{ width: '14%' }}>{row.member_id}</td>
            <td className="text-truncate" style={{ width: '10%' }}>{row.full_name}</td>
            <td className="text-truncate" style={{ width: '19%' }}>{row.address}</td>
            <td className="text-truncate" style={{ width: '7%' }}>{row.flat}</td>
            <td className="text-truncate" style={{ width: '13%' }}>{row.service_name}</td>
            <td className="text-truncate" style={{ width: '9%' }}>{row.amount}</td>
            <td className="text-truncate" style={{ width: '12%' }}>{row.payment_period}</td>
            <td className="text-truncate" style={{ width: '12%' }}>{row.date}</td>
            <td className="text-truncate" style={{ width: '8%' }}>
              <i
                className={`fas fa-trash ${deletePerm ? 'text-danger cursor-pointer' : ''}`}
                onClick={e => {
                  if (deletePerm) setSelectedBill(row)
                }}
                title='Çeki sil'
              />
            </td>
          </tr>
        );
      });
    }
    return null;
  }, [deletePerm]);

  const getTotalAmount = useCallback((results = null) => {
    if (results) {
      const total = results.reduce((value, item) => value + parseFloat(item.amount), 0)

      return total.toFixed(2)
    }
    return null;
  }, []);

  const startChange = useCallback((value) => {
    if (value instanceof moment) {
      setStart(value.format('YYYY-MM-DD'));
    } else if (!value) {
      setStart(null);
    }
  }, []);

  const endChange = useCallback((value) => {
    if (value instanceof moment) {
      setEnd(value.format('YYYY-MM-DD'));
    } else if (!value) {
      setEnd(null);
    }
  }, []);


  const toggle = useCallback(() => {
    setSelectedBill(null)
  }, [])


  return (
    <>
      <CardBody className="pt-5">
        <Row>
          <Col md="1" xs="0">

          </Col>
          <Col md="7">
            <DatePicker
              start={start}
              end={end}
              onStartChange={startChange}
              onEndChange={endChange}
            />
          </Col>

          <Col md="2" xs="6">
            <Button className="btn w-100"
              color="success"
              onClick={(e) => setExcel(true)}
            >
              Excel <i className="ml-1 fas fa-file-download"></i>
            </Button>
          </Col>
        </Row>

        <ul className="inspector-tabset mt-3">
          <li onClick={() => setActivePage('payment')} className={(activePage === 'payment') ? "inspector-tabset-tab active" : "inspector-tabset-tab"}>
            <span className="tab--link">
              ÖDƏNİŞ QƏBZLƏRİ
            </span>
          </li>
          <li className="inspector-tabset-tab">
            <span className="tab--link">
              YIĞIM CƏMİ : {total_amount}
            </span>
          </li>
          <li onClick={() => setActivePage('notification')} className={(activePage === 'notification') ? "inspector-tabset-tab active" : "inspector-tabset-tab"}>
            <span className="tab--link">
              BİLDİRİŞ QƏBZLƏRİ
            </span>
          </li>
        </ul>

        <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{(count) ? count : 0} Nəticə</span>
        <Table className="align-items-center custom-table-style" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col" style={{ width: '9%' }}>QƏBZ №</th>
              <th scope="col" style={{ width: '14%' }}>ABONENT KODU</th>
              <th scope="col" style={{ width: '10%' }}>AD, SOYAD, ATA ADI</th>
              <th scope="col" style={{ width: '19%' }}>ÜNVAN</th>
              <th scope="col" style={{ width: '7%' }}>Mənzil</th>
              <th scope="col" style={{ width: '13%' }}>VERGİ NÖVÜ</th>
              <th scope="col" style={{ width: '9%' }}>MƏBLƏĞ</th>
              <th scope="col" style={{ width: '9%' }}>Ödəniş dövrü</th>
              <th scope="col" style={{ width: '12%' }}>TARİX</th>
              <th scope="col" style={{ width: '8%' }}>Sil</th>
            </tr>

          </thead>
          <tbody>
            {getRows(results)}
          </tbody>
          <tfoot>
            <tr>
              <th scope="col" style={{ width: '9%' }}></th>
              <th scope="col" style={{ width: '15%' }}></th>
              <th scope="col" style={{ width: '10%' }}></th>
              <th scope="col" style={{ width: '20%' }}></th>
              <th scope="col" style={{ width: '8%' }}></th>
              <th scope="col" style={{ width: '15%', textAlign: 'right' }}>Səhifə cəmi: </th>
              <th scope="col" style={{ width: '10%' }}>{getTotalAmount(results)}</th>
              <th scope="col" style={{ width: '14%' }}></th>
            </tr>
          </tfoot>
        </Table>
      </CardBody>
      <CardFooter className="py-4">
        <nav aria-label="...">
          <Pagination
            count={pages}
            page={page}
            onChange={(_, page) => {
              setPage(page)
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
            }}
            className="pagination justify-content-end mb-0"
            color="primary"
          />
        </nav>
      </CardFooter>
      {deletePerm && selectedBill
        && <BillDeleteModal
          isOpen={!!selectedBill}
          toggle={toggle}
          bill={selectedBill}
        />}
    </>
  );
}

export default Bill
