import React, { useCallback, useState } from 'react';

import classnames from "classnames";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import Chart from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { useBarData } from 'hooks/chart';

import {
  chartOptions,
  parseOptions,
  // chartColors,
} from "variables/charts.js";

const BarData = props => {

  const { header, inspector } = props;

  const [activeNav, setActiveNav] = useState(1);

  const [activeBar, setActiveBar] = useState(true);

  const {
    data, labels
  } = useBarData({
    activeNav, inspector
  })

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  const getChartOptions = useCallback((labels, data) => {
    return {
      options: {
        scales: {
          yAxes: [{
            gridLines: {
              color: '#212529',
              zeroLineColor: '#212529',
            },
            ticks: {
              callback: (value) => {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: (item, data) => {
              const label = data.datasets[item.datasetIndex].label || "";
              let content = "";
              if (data.datasets.length > 1) {
                content += label;
              }
              content += item.yLabel;
              return content;
            },
          },
        },
      },
      data: {
        labels: labels,
        datasets: [
          {
            label: "Bildiriş ",
            data: (data) ? data[0] : [],
            maxBarThickness: 20,
            backgroundColor: "rgb(0, 214, 143)",
          },
          {
            label: "Yığım ",
            data: (data) ? data[1] : [],
            maxBarThickness: 18,
            backgroundColor: "rgb(51, 102, 255)",
          },
        ],
      },
      dataLine: {
        labels: labels,
        datasets: [
          {
            label: "Bildiriş ",
            data: (data) ? data[0] : [],
            borderColor: "rgb(0, 214, 143)",
            pointBackgroundColor: "rgb(0, 214, 143)",
            fill: false,
            tension: 0.1
          },
          {
            label: "Yığım ",
            data: (data) ? data[1] : [],
            borderColor: "rgb(51, 102, 255)",
            pointBackgroundColor: "rgb(51, 102, 255)",
            fill: false,
            tension: 0.1
          },
        ],
      },
    }
  }, []);


  const options = getChartOptions(labels, data);

  return (
    <Card className="shadow mt-5">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <Col xs="4">
            <h6 className="text-uppercase text-muted ls-1 mb-1">
              {header || "Hesabat"}
            </h6>
            <h2 className="mb-0">Aylıq / Həftəlik</h2>
          </Col>
          <Col xs="8">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3 rounded-circle", {
                    active: activeBar,
                  })}
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveBar(true);
                  }}
                  title="Sütun"
                >
                  <span className="d-none d-md-block">S</span>
                  <span className="d-md-none">S</span>
                </NavLink>
              </NavItem>
              <NavItem className='mr-2'>
                <NavLink
                  className={classnames("py-2 px-3 rounded-circle", {
                    active: !activeBar,
                  })}
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveBar(false);
                  }}
                  title="Xətti"
                >
                  <span className="d-none d-md-block">X</span>
                  <span className="d-md-none">X</span>
                </NavLink>
              </NavItem>
              <NavItem className='ml-2'>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: activeNav === 1,
                  })}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setActiveNav(1)
                  }}
                >
                  <span className="d-none d-md-block">Aylar üzrə</span>
                  <span className="d-md-none">A</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: activeNav === 2,
                  })}
                  data-toggle="tab"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setActiveNav(2)
                  }}
                >
                  <span className="d-none d-md-block">Həftə üzrə</span>
                  <span className="d-md-none">H</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {/* Chart */}
        <div className="chart">
          {data && (activeBar) ? <Bar
            data={options.data}
            options={options.options}
          /> : <Line
            data={options.dataLine}
            options={options.options}
          />
          }
        </div>
      </CardBody>
    </Card>
  );
}


export default BarData;
