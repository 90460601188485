import React, { useCallback, Fragment } from "react"
import { useRegion, useHouse } from "hooks/address";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label
} from 'reactstrap'



const Address = props => {

  const { method, setRegionHouse, region, house, disabled } = props;

  const regionList = useRegion()

  const houseList = useHouse(region)

  const getOptions = useCallback((list) => {
    return list.map((item, i) => {
      return (
        <option
          value={item.id}
          key={i}
        >
          {item.name}
        </option>
      )
    });
  }, []);

  return (
    <Fragment>
      {(method === 'create') ?
        <Fragment>
          <Col md="4">
            <FormGroup>
              <Label>Bölgə (Rayon / Küçə)</Label>
              <Input
                type="select"
                onChange={e => setRegionHouse((state) => {
                  return {
                    ...state,
                    region: e.target.value,
                  }
                })}
                value={region ?? ''}
                className="form-control form-control-sm"
              >
                <option value="" defaultValue={!region}>-------</option>
                {getOptions(regionList)}
              </Input>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>Bina</Label>
              <Input
                type="select"
                onChange={e => setRegionHouse((state) => {
                  return {
                    ...state,
                    house: e.target.value,
                  }
                })}
                value={house ?? ''}
                className="custom-form-control-sm"
              >
                <option value="" defaultValue={!house}>-------</option>
                {getOptions(houseList)}
              </Input>
            </FormGroup>
          </Col>

        </Fragment> :

        <Row>
          <Col md="4" className="mb-2">Bölgə (Rayon / Küçə):</Col>
          <Col md="8" className="mb-3">
            <Input
              type="select"
              onChange={e => setRegionHouse((state) => {
                return {
                  ...state,
                  region: e.target.value,
                }
              })}
              value={region ?? ''}
              className="custom-form-control-sm"
              disabled={disabled}
            >
              <option value="" defaultValue={!region}>-------</option>
              {getOptions(regionList)}
            </Input>
          </Col>
          <Col md="4" className="mb-2">Bina:</Col>
          <Col md="8" className="mb-3">
          <Input
            type="select"
            onChange={e => setRegionHouse((state) => {
              return {
                ...state,
                house: e.target.value,
              }
            })}
            value={house ?? ''}
            className="custom-form-control-sm"
            disabled={disabled}
          >
              <option value="" defaultValue={!house}>-------</option>
              {getOptions(houseList)}
          </Input>
          </Col>
        </Row>
      }
    </Fragment>
  );
};



export default Address;
