import React, { useEffect, useState, useCallback, Fragment } from "react"
import { DEBT } from "api/urls"
import DeleteDeptModal from "./DeleteDeptModal"
import EditDeptModal from "./EditDeptModal"
import AddDebt from './AddDebt'
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Table,
} from "reactstrap"
import { useCompany } from "store/company-context"

import { Pagination } from '@material-ui/lab'
import { errorNotification } from "helpers/notification"
import { checkPermission, EDIT } from "helpers/permission"


const PayerDebts = props => {

  const { id } = props

  const { permissions } = useCompany()

  const [deleteModal, setDeleteModal] = useState(false)
  const [deptId, setDeptId] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [debtData, setDebtData] = useState({
    service: 0,
    amount: 0,
    id: 0,
  })
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])
  const [pages, setPages] = useState(1)
  const [req, setReq] = useState(true)
  const [btnDisabled, setBtnDisabled] = useState(false)

  useEffect(() => {
    if (req) {
      const getDebts = async () => {
        const URL = DEBT + `${id}/?limit=100&page=${page}`
        const response = await fetch(URL, {
          method: "GET",
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          errorNotification();
          return
        }

        const data = await response.json();
        setResults(data.results);
        setPages(data.pages);
      }

      getDebts();
      setReq(false);

    }
  }, [req, id, page]);

  const init = useCallback(() => {
    setReq(true);
    setPage(1);
  }, [])

  const deleteModalToggle = useCallback(() => setDeleteModal(!deleteModal), [setDeleteModal, deleteModal]);

  const editModalToggle = useCallback(() => setEditModal(!editModal), [editModal, setEditModal])

  const deleteDebtBtnClick = useCallback((id) => {
    setDeptId(id);
    deleteModalToggle();
  }, [setDeptId, deleteModalToggle]);


  const editDebtBtnClick = useCallback((debt) => {
    setDebtData(debt);
    editModalToggle();
  }, [editModalToggle, setDebtData]);

  const getRows = useCallback((results = null, btnDisabled, deleteDebtBtnClick, editDebtBtnClick, permissions) => {
    if (results) {
      return results.map((row, key) => {
        let type = (row.type === 2) ? <Badge color="success">ÖDƏNİŞ</Badge> : <Badge color="danger">BORC</Badge>;
        let amount = (row.type === 2) ? row.amount * -1 : row.amount;
        return (
          <tr key={key}>
            <td>{row.service_name}</td>
            <td>{amount}</td>
            <td>{type}</td>
            <td>{row.created_at}</td>
            <td>{row.payment_period}</td>
            {checkPermission(permissions, EDIT) &&
              <td className="text-center">
                {(row.type !== 2) ?
                  <Fragment>
                    <i className="fas fa-edit text-warning mr-2 hover-pointer" disabled={btnDisabled} onClick={() => editDebtBtnClick({
                      id: row.id,
                      service: row.service,
                      amount: row.amount,
                    })}></i>
                    <i className="fas fa-trash-alt text-danger hover-pointer" disabled={btnDisabled} onClick={() => deleteDebtBtnClick(row.id)}></i>
                  </Fragment> : null
                }
              </td>
            }
          </tr>
        );
      });
    }
    return null;
  }, []);

  return (
    <Fragment>
      <Card className="shadow mt-3 mb-5">
        <CardHeader>
          <h2 className="">
            Borc/Ödəniş məlumatları
          </h2>
        </CardHeader>
        {(results && results.length) ?
          <CardBody>
            <Table className="align-items-center table-striped table-hover custom-table-style table-bordered" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Vergi növü</th>
                  <th scope="col">Məbləğ (₼)</th>
                  <th scope="col">Borc / Ödəniş</th>
                  <th scope="col">Yaranma tarixi</th>
                  <th scope="col">Ödəniş dövrü</th>
                  {checkPermission(permissions, EDIT) &&
                    <th scope="col">Əməliyyat</th>
                  }
                </tr>
              </thead>
              <tbody>
                {getRows(results, btnDisabled, deleteDebtBtnClick, editDebtBtnClick, permissions)}
              </tbody>
            </Table>

            <nav aria-label="..." className="mt-2">
              <Pagination
                count={pages}
                page={page}
                onChange={(_, page) => {
                  setPage(page)
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                  setReq(true)
                }}
                className="pagination justify-content-end mb-0"
                color="primary"
              />
            </nav>
          </CardBody> : null
        }
        {checkPermission(permissions, EDIT) &&
          <CardFooter className="py-4">
            <AddDebt
              init={init}
              payerId={id}
            />
          </CardFooter>}
      </Card>

      {checkPermission(permissions, EDIT) &&
        <DeleteDeptModal
          modal={deleteModal}
          modalToggle={deleteModalToggle}
          deptId={deptId}
          init={init}
          id={id}
        />}

      {checkPermission(permissions, EDIT) &&
        <EditDeptModal
          payerId={id}
          init={init}
          modal={editModal}
          modalToggle={editModalToggle}
          values={debtData}
          setValues={setDebtData}
          onBtnDisabledEvent={setBtnDisabled}
          btnDisabled={btnDisabled}
        />
      }
    </Fragment>
  )
}


export default PayerDebts;
