import React, { useState, useEffect } from 'react';
import { PAYER_DETAIL } from 'api/urls';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from 'reactstrap';

import { successNotfication, errorNotification } from 'helpers/notification';


const DeleteDeptModal = props => {

  const [del, setDel] = useState(false);

  const {
    modal, modalToggle, deptId, init, id
  } = props;


  useEffect(() => {
    if (del) {
      const deleteDept = async (debtId) => {
        const URL = PAYER_DETAIL + `${id}/debts/${debtId}/`;
        const response = await fetch(URL, {
          method: "DELETE",
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        });

        if ([200, 201].includes(response.status)) {
          successNotfication();
          init();
          setDel(false);
          modalToggle();
        } else {
          errorNotification();
          setDel(false)
          modalToggle();
        }
      }

      deleteDept(deptId);
    }
  }, [del, deptId, id, init, modalToggle]);

  return (
    <Modal
      isOpen={modal}
      toggle={modalToggle}
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
    >
      <ModalHeader toggle={modalToggle}></ModalHeader>
      <ModalBody>
        <div className="py-2 text-center">
          <i className="fas fa-exclamation-triangle fa-3x"></i>
          <h3 className="mt-2 text-white">Borc məlumatını silmək isdədiyinizə əminsinizmi?</h3>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-white" color="default" onClick={() => setDel(true)}>Bəli, silinsin</Button>{' '}
        <Button className="text-white ml-auto" color="link" onClick={modalToggle}>Ləğv et</Button>
      </ModalFooter>
    </Modal>
  );
}


export default DeleteDeptModal;
