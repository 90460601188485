
import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import { useCompany } from "store/company-context";
import { useLogout } from "hooks/logout";
import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Media,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";


// import navbarToggle from 'store/actions/navbarToggle';
import { navbarActions } from "store/slices/navbar-slice";
import { useDispatch } from 'react-redux';
import { strToUpper, shortName } from 'helpers/utils';
import SearchBar from './SearchBar';

const FinanceNavbar = (props) => {

  const { userFullName, username, userPhoto } = useCompany()

  const dispatch = useDispatch();
  const logout = useLogout()

  const logo = props.logo;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }


  const userName = (userFullName) ? userFullName : (username) ? username : "";


  return (
    <>
      <Navbar className="navbar-nav navbar-light bg-white fixed-top custom-navbar-style" expand="md" id="navbar-main">
        <div className="custom-navbar container-fluid">
          <button
            className="custom-sidebar-toggler mr-2 ml-0 text-dark"
            type="button"
            onClick={() => dispatch(navbarActions.toggle())}
          >
            <svg xmlns="http://www.w3.org/2000/svg"
              width="100%" height="100%"
              viewBox="0 0 24 24"
              fill="rgba(0, 0, 0, 0.5)">
              <g data-name="Layer 2">
                <g data-name="menu-2">
                  <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                  <circle cx="4" cy="12" r="1"></circle><rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
                  <rect x="3" y="16" width="18" height="2" rx=".94" ry=".94"></rect><rect x="3" y="6" width="18" height="2" rx=".94" ry=".94">
                  </rect></g></g></svg>
          </button>
          {logo ? (
            <NavbarBrand className="pt-0 ml-2 mr-auto" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img d-inline"
                src={logo.imgSrc}
                width={"30px"}
              />
              <h3 className="pl-3 d-inline">{strToUpper(props.companyName)}</h3>
              {props.management &&
                <Fragment><span className="ml-3 mr-3"><i className="fas fa-angle-right" /></span>
                  <h3 className="d-inline">{strToUpper(props.management.name)}</h3>
                </Fragment>
              }
            </NavbarBrand>
          ) : null}


          <SearchBar {...props} />

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="mr-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {strToUpper(userName)}
                    </span>
                  </Media>
                  {(userPhoto) ? <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={userPhoto}
                    />
                  </span> :
                    <span className="profile-rounded-circle text-dark">
                      {shortName(userName)}
                    </span>
                  }
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem href="#" onClick={(e) => {
                  e.preventDefault();
                  logout(true);
                }}>
                  <i className="fas fa-sign-out-alt" />
                  <span>Çıxış</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
      </Navbar>
    </>
  );
};



export default FinanceNavbar;
