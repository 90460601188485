import React, { useState, useCallback, useEffect } from "react";
// import { useDispatch, useSelector } from 'react-redux';
// import payersData, { payersDataExcel } from 'store/actions/payers';
// import { PAYERS } from "api/urls";
import { shortName } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from "react-router-dom";
import { checkPermission, CREATE } from "helpers/permission";
import { useCompany } from "store/company-context";
import { usePayers } from "hooks/payer";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  NavLink,
} from "reactstrap";

import { Pagination } from '@material-ui/lab'

import { useLocation, useParams } from "react-router-dom";

const Payer = props => {
  const { permissions } = useCompany();
  const [page, setPage] = useState(1);
  const [memberId, setMemberId] = useState(() => {
    // Retrieve memberId from localStorage on component mount
    return localStorage.getItem('selectedMemberId') || '';
  });
  const [fullName, setFullName] = useState(() => {
    // Retrieve memberId from localStorage on component mount
    return localStorage.getItem('selectedfullName') || '';
  });
  const [payerAddress, setPayerAdress] = useState(null);
  const [amount, setAmount] = useState(null);
  const [flat, setFlat] = useState(null);
  const [debtStatus, setDebtStatus] = useState(null);
  const [excel, setExcel] = useState(false);

  const { manager } = useParams();
  // useEffect to update localStorage when memberId changes
  useEffect(() => {
    localStorage.setItem('selectedMemberId', memberId);
    localStorage.setItem('selectedfullName', fullName);
  }, [memberId, fullName]);


  const {
    count, pages, results
  } = usePayers({
    page, memberId, fullName, payerAddress, amount, debtStatus, excel, flat, setExcel
  })

  const { history } = props;

  const location = useLocation();
  const addLink = useCallback(() => {
    let link = location.pathname;
    if (link.slice(-1) === "/") {
      link = link.slice(0, -1);
    }

    return link + "/add";
  }, [location.pathname]);

  const handleRowClick = useCallback((member_id) => {
    history.replace(`/${manager}/payers/${member_id}`);
  }, [history, manager]);



  const getRows = useCallback((result = null) => {
    if (result) {
      return result.map((row, key) => {
        let debt = <Badge color="success">ÖDƏNİLİB</Badge>;
        if (row.final_amount && row.final_amount > 0) {
          debt = <Badge color="danger">BORCLU</Badge>;
        }
        return (
          <tr key={key} onDoubleClick={() => { handleRowClick(row.id) }} >
            <td className="text-truncate" style={{ width: '22%' }}>
              {(row.full_name) ? <Media className="align-items-center">
                <Media className="d-lg-block w-25">
                  <span className="profile-rounded-circle profile-rounded-circle-sm">
                    {shortName(row.full_name)}
                  </span>
                </Media>
                <Media className="d-lg-block w-75">
                  <span className="mb-0">
                    {row.full_name}
                  </span>
                </Media>
              </Media> : null}
            </td>
            <td className="text-truncate" style={{ width: '28%' }}>{row.address}</td>
            <td className="text-truncate" style={{ width: '10%' }}>{row.flat}</td>
            <td className="text-truncate" style={{ width: '20%' }}>{row.member_id}</td>
            <td className="text-truncate" style={{ width: '10%' }}>{(row.amount) ? row.amount : 0}</td>
            <td className="text-truncate" style={{ width: '10%' }}>{debt}</td>
          </tr>
        );
      });
    }
    return null;
  }, [handleRowClick]);


  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" href="#" className="p-0" disabled>
          Abonentlər Əhali
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h3 className="mb-0 custom-card-header">Abonentlər Əhali</h3>
            </Col>
            <Col>
              <div className="float-right clear-fix">
                {checkPermission(permissions, CREATE) && <Link
                  className="btn btn-info"
                  to={addLink}
                >
                  <i className="mr-1 fas fa-plus"></i> Yeni Abonent
                </Link>}
                <Button className="btn"
                  color="success"
                  onDoubleClick={(e) => setExcel(true)}
                >
                  Excel <i className="ml-1 fas fa-file-download"></i>
                </Button>
              </div>
            </Col>
          </Row>

        </CardHeader>
        <hr className="my-1" />
        <CardBody className="w-100">
          <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{(count) ? count : 0} Nəticə</span>
          <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ width: '22%' }}>Ad, Soyad, Ata adı</th>
                <th scope="col" style={{ width: '28%' }}>ÜNVAN</th>
                <th scope="col" style={{ width: '10%' }}>MƏNZİL</th>
                <th scope="col" style={{ width: '20%' }}>ABONENT KODU</th>
                <th scope="col" style={{ width: '10%' }}>BORC</th>
                <th scope="col" style={{ width: '10%' }}>BORC STATUSU</th>
              </tr>
              <tr>
                <th scope="col" style={{ width: '22%' }}>
                  <Input placeholder="Ad, Soyad, Ata adı" type="text" value={fullName || ''} onChange={(e) => {
                    setFullName(e.target.value);
                    setPage(1);
                  }} className="form-control-sm" />
                </th>
                <th scope="col" style={{ width: '28%' }}>
                  <Input placeholder="Ünvan" type="text" className="form-control-sm" onChange={(e) => {
                    setPayerAdress(e.target.value)
                    setPage(1);
                  }} />
                </th>
                <th scope="col" style={{ width: '10%' }}>
                  <Input placeholder="Mənzil" type="text" className="form-control-sm" onChange={(e) => {
                    setFlat(e.target.value)
                    setPage(1);
                  }} />
                </th>
                <th scope="col" style={{ width: '20%' }}>
                  <Input placeholder="Abonent kodu" type="text" value={memberId || ''} onChange={(e) => {
                    setMemberId(e.target.value)
                    setPage(1);
                  }} className="form-control-sm" />
                </th>
                <th scope="col" style={{ width: '10%' }}>
                  <Input placeholder="₼" type="number" className="form-control-sm" onChange={(e) => {
                    setAmount(e.target.value)
                    setPage(1);
                  }} />
                </th>
                <th scope="col" style={{ width: '10%' }}>
                  <Input
                    placeholder="Status"
                    type="select"
                    className="form-control-sm"
                    onChange={(e) => {
                      setDebtStatus(e.target.value)
                      setPage(1);
                    }}
                  >
                    <option value="" defaultValue>-----</option>
                    <option value="indebted">Borclu</option>
                    <option value="paid">Ödənilib</option>
                  </Input>
                </th>
              </tr>
            </thead>
            <tbody>
              {getRows(results)}
            </tbody>
          </Table>
        </CardBody>
        {(results) ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                count={pages}
                page={page}
                onChange={(_, page) => {
                  setPage(page)
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
                className="pagination justify-content-end mb-0"
                color="primary"
              />
            </nav>
          </CardFooter> : null}
      </Card>
    </Container>
  );
}


export default Payer;
