

import { configureStore } from '@reduxjs/toolkit';
import navbarReducer from './slices/navbar-slice';
import regionReducer from './slices/region-slice';
import resetReducer from './slices/reset-slice';
import dashboardReducer from './slices/dashboard-slice';
const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    region: regionReducer,
    reset: resetReducer,
    dashboard: dashboardReducer,
  }
});


export default store;
